import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nuovo-contatto',
  templateUrl: './nuovo-contatto.component.html'
})
export class NuovoContattoComponent implements OnInit {
  
  @Output() tornaAllaLista = new EventEmitter<any>();

  contattoFornitore: any = {};

  testoContatto = 'Nuovo contatto';
  ruoli = [
    {codice: "AMM", descrizione: "Ufficio amministrativo/finanziario"},
    {codice: "COM", descrizione: "Referente commerciale"},
    {codice: "GAR", descrizione: "Ufficio gare / Referente RDO"},
    {codice: "ECO", descrizione: "Ufficio gestione ordini"},
  ];

  constructor() {
    // do nothing
  }

  ngOnInit() {
    // do nothing
  }

  annullaModifiche(){
    this.tornaAllaLista.emit(null);
  }

  salvaContatto(){
    this.tornaAllaLista.emit(this.contattoFornitore);
  }
}
