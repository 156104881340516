import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../_service/auth.service';

@Injectable({providedIn: 'root'})
export class UserRedirectGuard implements CanActivate {
  
  constructor(private router: Router, private authService: AuthService) { }

  canActivate() {
    if (localStorage.token && localStorage.user) {
      switch (this.authService.userType) {
        case 'FRN': this.router.navigate(['/fornitori/home']); break;
        case 'OSP': this.router.navigate(['/ospedale/home']); break;
        case 'ADM': this.router.navigate(['/main/home']); break;
        default: break;
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
