<form #form="ngForm" id="tagForm">
  <div class="d-flex my-4">
    <div class="mr-auto">
      <h3 class="text-uppercase color-cprimary">TAG</h3>
    </div>
    <div class="pl-3">
      <button class="btn btn-bg-empty px-3 mr-1" (click)="indietro()">INDIETRO</button>
      <button class="btn btn-bg-full px-3" (click)="continua()" [disabled]="listaTagFornitore.length === 0">CONTINUA</button>
    </div>
  </div>

  <table class="table table-hover my-4 tabellaConLista">
    <thead>
        <tr class="row">
            <th scope="col" class="border-bottom-0 col-3">Generale</th>
            <th scope="col" class="border-bottom-0 col-3">Primaria</th>
            <th scope="col" class="border-bottom-0 col-5">Secondaria</th>
            <th scope="col" class="border-bottom-0 col-1"></th>
        </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tag of listaTagFornitore" class="row">
        <td class="col-3">{{tag.descrizionePrimo}}</td>
        <td class="col-3">{{tag.descrizioneSecondo}}</td>
        <td class="col-5">{{tag.descrizioneTerzo}}</td>
        <td class="text-right col-1">
          <button type="button" class="btn btn-no-btn ml-2">
            <i class="fa fa-trash" title="Rimuovi" (click)="cancellaTag(tag)"></i>
          </button>
        </td>
      </tr>
      <tr class="row">
        <td class="col-3">
          <select name="tagUno" class="form-control" [ngModel]="tagGeneraleSelezionato" (ngModelChange)="selezionaTagGenerale($event)">
            <option [value]="undefined">Seleziona un TAG</option>
            <option *ngFor="let tag of listaTagDominio" [ngValue]="tag">{{tag.descrizione}}</option>
          </select>
        </td>
        <td class="col-3" *ngIf="tagGeneraleSelezionato && tagGeneraleSelezionato.figli">
          <select name="tagDue" class="form-control" [ngModel]="tagPrimarioSelezionato" (ngModelChange)="selezionaTagPrimario($event)">
            <option [value]="undefined">Seleziona un TAG</option>
            <option *ngFor="let tag of tagGeneraleSelezionato.figli" [ngValue]="tag">{{tag.descrizione}}</option>
          </select>
        </td>
        <td class="col-5" *ngIf="tagPrimarioSelezionato && tagPrimarioSelezionato.figli">
          <ng-multiselect-dropdown name="tagTre" [placeholder]="'Seleziona uno o più TAG'" [settings]="dropdownSettings" [data]="tagPrimarioSelezionato.figli"
            [(ngModel)]="tagSecondariSelezionati">
          </ng-multiselect-dropdown>
        </td>
        <td class="text-right col-1">
          <button type="button" class="btn btn-bg-full ml-2" *ngIf="tagSecondariSelezionati" (click)="aggiungiTag()">
            <i class="fa fa-plus" title="Aggiungi"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</form>