import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from "@angular/core";

export interface Dominio {
  codice: string;
  descValues: {IT: string},
  descrizione: string,
  tipo: string
}

@Injectable()
export class DominiService {
  constructor(private http: HttpClient) {}

  listaTipologieRDO() {
    const params = new HttpParams()
    .set('lingua', environment.lang);

    return this.http.get(environment.apiUrl + 'domini/tipo-rdo/list', { params }).pipe(map((res: any) => res));
  }

  listaArea() {
    const params = new HttpParams()
    .set('lingua', environment.lang);

    return this.http.get(environment.apiUrl + 'domini/area/list', { params }).pipe(map((res: any) => res));
  }

  listaProfili() {
    const params = new HttpParams()
    .set('lingua', environment.lang);

    return this.http.get(environment.apiUrl + 'domini/profilo/list', { params });
  }

  listaTipologieDocumenti(): Observable<Dominio[]> {
    const params = new HttpParams()
    .set('lingua', environment.lang);

    return this.http.get<Dominio[]>(environment.apiUrl + 'domini/tipo-doc/list', { params });
  }

  listaRuoliLegale() {
    return this.http.get(environment.apiUrl + 'domini/ruolo/legale/list').pipe(map((response: any) => response));
  }
}
