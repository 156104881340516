import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  message = 'Elimina';
  messageBody = null;
  button = 'Elimina';

  ngOnInit() {
    // do nothing
  }

}

export function conferma(modalService: NgbModal, message, button, messageBody?): Promise<boolean> {
  const modal =  modalService.open(ConfirmModalComponent, {size: 'md'});
  modal.componentInstance.message = message;
  modal.componentInstance.button = button;
  if (messageBody) {
    modal.componentInstance.messageBody = messageBody;
  }
  return modal.result;
}

