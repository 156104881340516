import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OffertaEconomicaService {

  constructor() {
    // do nothing
  }
}
