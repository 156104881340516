import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Domanda } from '../const';
import { Injectable } from "@angular/core";

@Injectable()
export class VendorRatingService {
  constructor(private http: HttpClient) { }

  /*******************************
  ******** VENDOR RATING *********
  *******************************/
  listaDomande(domandeAttive: boolean | null): Observable<any> {
    if (domandeAttive == false || domandeAttive == true ) {
      return this.http.get(environment.apiUrl + 'vendorRating/listaCompletaDomande?domandeAttive=' + domandeAttive).pipe(map((res: any) => res));
    } else {
      return this.http.get(environment.apiUrl + 'vendorRating/listaCompletaDomande').pipe(map((res: any) => res));

    }
  }

  creaModificaDomanda(jsonIn: Domanda): Observable<any> {
    return this.http.post(environment.apiUrl + 'vendorRating/creaAggiornaDomanda', jsonIn).pipe(map((response: any) => response));
  }

  deleteDomanda(idDomanda: number) {
    return this.http.delete(environment.apiUrl + 'vendorRating/cancellaDomanda/' + idDomanda, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  listaDomandeRisposteRdo(idRdo: number | string){
    return this.http.get(environment.apiUrl + 'vendorRating/listaDomandeRisposte?idRdo=' + idRdo).pipe(map((res: any) => res));
  }

  creaModificaDomandeRisposteRdo(jsonIn: Domanda[]): Observable<any> {
    return this.http.post(environment.apiUrl + 'vendorRating/creaAggiornaRisposte', jsonIn).pipe(map((response: any) => response));
  }

  listaRdoConDomande(codArea: string) {
    return this.http.get(environment.apiUrl + 'vendorRating/listaRdoRating?codArea=' + codArea).pipe(map((res: any) => res));
  }
}
