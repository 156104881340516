<form #form="ngForm" id="dettagliForm">
  <div class="d-flex my-4">
    <div class="mr-auto">
      <h5 class="text-uppercase color-cprimary">Informazioni Aziendali</h5>
    </div>
    <div class="pl-3">
      <button class="btn btn-bg-full px-3" (click)="continua()" [disabled]="form.invalid">CONTINUA</button>
    </div>
  </div>

  <div class="alert alert-success d-flex" role="alert" *ngIf="!nascondiEsito">
    Salvataggio avvenuto con successo!
  </div>

  <div class="row mb-3">
    <div class="col-md-8">
      <div class="form-group">
        <label>Ragione Sociale <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="ragioneSociale" name="ragioneSociale" [(ngModel)]="dettaglioFornitore.ragioneSociale" required/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Codice fiscale <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="codiceFiscale" name="codiceFiscale" [(ngModel)]="dettaglioFornitore.codiceFiscale" required/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Partita IVA<span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="partitaIva" name="partitaIva" [(ngModel)]="dettaglioFornitore.partitaIva" required/>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-12">
      <h5 class="text-uppercase color-cprimary">Recapito</h5>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-8">
      <div class="form-group">
        <label>Indirizzo <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="via" name="via" [(ngModel)]="dettaglioFornitore.via" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Nazione <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="nazione" name="nazione" [(ngModel)]="dettaglioFornitore.nazione" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Città <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="citta" name="citta" [(ngModel)]="dettaglioFornitore.citta" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>CAP <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="cap" name="cap" [(ngModel)]="dettaglioFornitore.cap" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Telefono <span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="telefono" name="telefono" [(ngModel)]="dettaglioFornitore.telefono" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>PEC <span class="txt-red">*</span></label>
        <input #pec="ngModel" type="text" maxlength="200" class="form-control" id="pec" name="pec" [(ngModel)]="dettaglioFornitore.pec" required email/>
      </div>
    </div>
  </div>
</form>

<ng-template #privacyContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Informativa ai sensi degli artt. 13 e 14 del Regolamento (UE) 679/2016 per persone fisiche</h4>
  </div>
  <div class="modal-body">
    <p>
      Ai sensi degli artt. 13 e ss. del Regolamento (UE) 2016/679 (di seguito “GDPR”), La informiamo che la società Ospedal Grando S.p.A. 
      (di seguito “<b>Titolare</b>” o “<b>OG</b>”) tratta, in qualità di Titolare del trattamento ai sensi dell’art. 26 del GDPR, 
      dati personali che La riguardano nel rispetto delle disposizioni del GDPR e della normativa nazionale in materia di protezione dei dati personali 
      (in particolare, il d.lgs. 196/2003 come novellato dal d.lgs. 101/2018).
    </p>
    <p>
        Sono “dati personali” le informazioni riguardanti una persona fisica identificata o identificabile (“<b>Interessato</b>”).
    </p>

    <ol>
      <li>
        <p><b>
          Titolari del trattamento di dati personali e relativi dati di contatto. 
        </b></p>
        <p>
          Il Titolare del trattamento è Ospedal Grando S.p.A., con sede in Milano, via della Moscova n. 3 – 20121 i cui dati di contatto sono: 
          tel. +39 026679180, e-mail <a href="mailto:privacy.italia@lendlease.com">privacy.italia@lendlease.com</a>.
        </p>
      </li>
      <li>
        <p><b>
          Finalità e base giuridica del trattamento. 
        </b></p>
        <p>
          Sono trattati i dati personali da Lei liberamente conferiti durante la registrazione presso la piattaforma di gestione delle procedure di 
          selezione di OG, da Lei stesso effettuata e automaticamente raccolti su tale piattaforma (di seguito “<b>Piattaforma</b>”). 
          La finalità è acquisire dati personali relativi ad aspiranti fornitori per valutarne l’idoneità a qualificarsi per futuri progetti nel 
          contesto di procedure di selezione indette dal Titolare. La base giuridica è data dall’art 6, par. 1, lett b) del GDPR, 
          in quanto il trattamento è necessario all'esecuzione di misure precontrattuali adottate su richiesta dell’Interessato, 
          nonché dall’art. 6, par. 1., lett. f) del GDPR, considerati i legittimi interessi del Titolare di poter verificare il possesso delle 
          necessarie e opportune qualifiche in conformità agli standard qualitativi applicabili ai servizi richiesti dal Titolare in capo agli 
          aspiranti fornitori.
        </p>
        <p>
          In particolare, potranno essere trattati i seguenti dati personali:
        </p>
        <ul>
          <li>
            dati identificativi e professionali, anche riferiti ai membri del team esperto che eventualmente fornirà i servizi, al fine di verificare 
            la sussistenza dei requisiti tecnici e professionali necessari per la fornitura dei servizi, già in fase di procedura di selezione e 
            trattativa precontrattuale, perseguendo il legittimo interesse di richiedere la dimostrazione dei sopracitati requisiti in capo 
            all’Interessato. La base giuridica è data dall’art. 6, lett. b), f), par. 1 del GDPR. 
          </li>
          <li>
            dati amministrativi e fiscali per l’adempimento di obblighi legali e fiscali ai sensi dell’art. 6 lett. c), par. 1 del GDPR.
          </li>
          <li>
            dati personali relativi all’assenza di condanne penali e di reati o di connesse misure di sicurezza con riferimento alle persone 
            fisiche indicate nell’art. 80, par. 3, del d.lgs. 50/2016, solo se il trattamento dei dati è strettamente necessario e autorizzato 
            dalla normativa vigente, nel rispetto dell’art. 10 del GDPR e dell’art. 2-octies del novellato d.lgs. 196/2003. In tal caso, 
            saranno trattati i dati personali previsti dall’articolo 80 del d.lgs. 50/2016.
          </li>
          <li>
            dati personali relativi all’assenza delle cause di decadenza, sospensione o divieto di cui all'art. 67 del d.lgs. 159/2011, 
            nonché l’assenza di eventuali tentativi di infiltrazione mafiosa di cui all’art.84, comma 4 del medesimo decreto. 
            La base giuridica è data dalla lett. c), par. 1, art. 6 e art. 10 del GDPR e dell’art. 2-octies del novellato d.lgs. 196/2003. 
          </li>
        </ul>

        <p>
          Con riferimento all’ultimo punto di cui sopra, OG potrà trattare altresì i dati identificativi e di contatto 
          (nome, cognome, data e luogo di nascita, luogo di residenza e codice fiscale) dei familiari conviventi maggiori d’età, 
          essendo tale trattamento necessario per adempiere un obbligo legale al quale è soggetto il titolare del trattamento ai sensi della lett. c), 
          par. 1, art. 6 del GDPR. 
        </p>
      </li>
      <li>
        <p><b>
          Natura obbligatoria o facoltativa del conferimento; conseguenze di un eventuale rifiuto. 
        </b></p>
        <p>
          I dati trattati sono liberamente conferiti ma necessari per le finalità di cui al paragrafo 2. In mancanza di detto conferimento, pertanto, 
          la partecipazione alla selezione non risulterebbe possibile.
        </p>
      </li>
      <li>
        <p><b>
          Categorie di destinatari dei dati personali. 
        </b></p>
        <p>
          I dati personali potrebbero essere comunicati a società del Gruppo Lendlease che li trattano in qualità di responsabili del trattamento o, 
          se del caso, come autonomi titolari.
        </p>
      </li>
      <li>
        <p><b>
          Trasferimenti al di fuori dell’UE. . 
        </b></p>
        <p>
          Gli eventuali trasferimenti di dati personali a Società del Gruppo Lendlease al di fuori della UE, nei Paesi che non offrono un 
          livello di protezione dei dati adeguato, avverranno nel rispetto di un accordo infragruppo per il trasferimento dei dati fra le 
          Società del Gruppo Lendlease, che prevede l’adozione delle clausole contrattuali standard approvate dalla Commissione europea per 
          i trasferimenti da titolare del trattamento a responsabile del trattamento. Per ottenere copia di tali eventuali dati o il luogo dove 
          sono stati resi disponibili scrivere ai contatti sopraindicati.
        </p>
      </li>
      <li>
        <p><b>
          Periodo di conservazione. 
        </b></p>
        <p>
          I dati sono conservati in un formato strutturato, di uso comune e leggibile da dispositivo automatico per il tempo previsto per l’utilizzo 
          della Piattaforma e, successivamente, fino al sussistere di eventuali connesse esigenze documentali. Anche con controlli periodici, 
          è verificata la stretta pertinenza, non eccedenza e necessità dei dati in relazione alle finalità del trattamento. I dati che risultano 
          eccedenti o non pertinenti o non indispensabili non possono essere utilizzati, salvo che per l’eventuale conservazione, a norma di legge, 
          dell’atto o del documento che li contiene.
        </p>
      </li>
      <li>
        <p><b>
          Fonte dei dati. 
        </b></p>
        <p>
          I dati personali sono comunicati a OG da parte dell’Interessato per le sopracitate finalità. Oltre ai dati forniti dal dichiarante nella 
          dichiarazione ex art. 80 del d.lgs. 50/2016, ai fini delle opportune verifiche, saranno consultate banche dati gestite da soggetti terzi 
          quali aziende specializzate nel Risk & Credit Management ovvero da altre fonti accessibili al pubblico.
        </p>
        <p>
          Con riferimento ai dati personali richiesti dal d.lgs. 159/2011 relativi ai familiari conviventi maggiori d’età, la fonte dei dati è il 
          soggetto tenuto alla dichiarazione ai sensi di legge. 
        </p>
      </li>
      <li>
        <p><b>
          Reclamo all’Autorità di controllo o all’Autorità giudiziaria. 
        </b></p>
        <p>
          Per l’esercizio dei suoi diritti, ha il diritto di proporre reclamo al Garante per la protezione dei dati, Piazza Venezia, 11, 00187, Roma, 
          ovvero di proporre ricorso all’autorità giudiziaria.
        </p>
      </li>
      <li>
        <p><b>
          I diritti previsti per l’Interessato dagli art. 15-22 del GDPR. 
        </b></p>
        <p>
          Il GDPR Le conferisce il diritto di accesso ai dati personali, di rettifica o cancellazione dei medesimi, di ottenere la limitazione del 
          trattamento ovvero l’opposizione allo stesso e la portabilità.
          <br/>
          Il diritto di accesso ai dati può essere esercitato ad intervalli ragionevoli, al fine di avere una corretta e costante informazione sul 
          trattamento e di verificarne la liceità.
          <br/>
          Il diritto alla cancellazione riguarda i dati personali trattati in violazione della legge o negli altri casi previsti dall’art. 17 del GDPR. 
          <br/>
          Può ottenere dal Titolare la limitazione del trattamento per il periodo necessario alla verifica dei dati personali di cui contesta l’esattezza, 
          ovvero quando il trattamento è illecito, preferendo la limitazione alla cancellazione dei dati, o qualora abbia la necessità di disporre di tali 
          dati per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria, anche se il Titolare non ne abbia più bisogno ai fini del 
          trattamento, ovvero per il periodo necessario alla verifica in merito all’eventuale prevalenza dei motivi legittimi del Titolare rispetto ai 
          Suoi e per i quali si è opposto al trattamento. In caso di limitazione, i dati personali saranno trattati solo con il Suo consenso o per 
          l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria oppure per tutelare i diritti di un’altra persona fisica o giuridica 
          o per motivi di interesse pubblico rilevante dell’Unione Europea o di uno Stato membro.  In ogni caso verrà informato dal Titolare prima che 
          questi proceda alla revoca di detta limitazione.
          <br/>
          Per motivi connessi alla Sua situazione, ha il diritto di opporsi al trattamento di dati necessario per l’esecuzione di un compito di interesse 
          pubblico o connesso all’esercizio di pubblici poteri di cui è investito il Titolare del trattamento, ovvero per il perseguimento del legittimo 
          interesse del Titolare. Il Titolare si asterrà dal trattare ulteriormente tali dati, a meno che non dimostri l’esistenza di motivi legittimi 
          cogenti che prevalgono sui Suoi. Può opporsi al trattamento quando i dati personali siano trattati per finalità di marketing diretto, compresa 
          la profilazione. Il diritto può essere esercitato in qualsiasi momento. 
          <br/>
          Ha diritto alla portabilità dei dati (la possibilità di ricevere i dati forniti ed eventualmente di trasmetterli ad altro Titolare del 
          trattamento) nei casi sia ammesso dal GDPR (art. 20).
          <br/>
          Ha diritto a non essere sottoposto a decisione basata esclusivamente su un trattamento automatizzato, compresa la profilazione. Eccezionalmente, 
          tale tipo di trattamento è possibile se autorizzato dal diritto dell’Unione o dello Stato membro cui è soggetto il Titolare, ovvero se da Lei 
          consentito o se necessario a fini contrattuali. Nelle ultime due ipotesi ha comunque il diritto di ottenere un intervento umano da parte del 
          Titolare, di esprimere la propria opinione e di contestare la decisione. Per l’esercizio di tali diritti può rivolgersi ai contatti sopra indicati.
        </p>
      </li>
    </ol>

    <div class="mt-3 text-right">
      <button type="button" class="btn btn-bg-full" (click)="modal.close('Close click')">ACCETTO</button>
    </div>
  </div>
</ng-template>