import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../_service/toast.service';

@Component({
  selector: 'app-toasts',
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('500ms ease-in')),
      transition(':leave', animate('1000ms ease-out')),
    ])
  ],
  template: `
  <div style="margin-top: 90px;" class="mr-3">
  <ngb-toast
      @fade
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay"
      (hide)="toastService.remove(toast)"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  </div>
  `,
  // tslint:disable-next-line: no-host-metadata-property
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
