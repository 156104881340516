<ul class="p-2 nav flex-column menu-impostazioni overflow-y: auto" id="menuAmministrazione"   >
  <li class="nav-item">
    <a class="nav-link" (click)="changeOpen()">
      <i class="fas fa-cog w-20px"></i>
      <span class="d-none d-xl-inline px-2 text-uppercase">Impostazioni</span>
    </a>
  </li>
  <div *ngIf="open" class="toggleContentImpostazioni pt-4">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/main/configurazioni/utenti']" routerLinkActive="active-link">
      <i class="fas fa-users w-20px"></i>
      <span class="d-none d-xl-inline px-2">Gestione utenti</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/main/configurazioni/gestione-templates']" routerLinkActive="active-link">
      <i class="fas fa-file-code w-20px"></i>
      <span class="d-none d-xl-inline px-2">Gestione templates</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/main/configurazioni/gestione-documenti']" routerLinkActive="active-link">
      <i class="fas fa-file w-20px"></i>
      <span class="d-none d-xl-inline px-2">Gestione documenti</span>
    </a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link" [routerLink]="['/main/configurazioni/gestione-tag']" routerLinkActive="active-link">
      <i class="fas fa fa-tags w-20px"></i>
      <span class="d-none d-xl-inline px-2">Gestione TAG</span>
    </a>
  </li> -->
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/main/configurazioni/vendor-rating']" routerLinkActive="active-link">
      <i class="fas fa-comment-dots w-20px"></i>
      <span class="d-none d-xl-inline px-2">Vendor rating</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/main/configurazioni/gestione-faq']" routerLinkActive="active-link">
      <i class="fas fa-comments w-20px"></i>
      <span class="d-none d-xl-inline px-2">Gestione F.A.Q.</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link mb-3" [routerLink]="['/main/configurazioni/download-rdo']" routerLinkActive="active-link">
      <i class="fas fa-download w-20px"></i>
      <span class="d-none d-xl-inline px-2">Download RDO</span>
    </a>
  </li>
</div>

</ul>
