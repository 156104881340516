import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagService } from 'src/app/shared/_service/tag.service';
import { OgModalComponent } from 'src/app/shared/modals/og-modal/og-modal.component';
import { conferma } from 'src/app/shared/util';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styles: []
})
export class TagComponent implements OnInit {

  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();

  listaTagFornitore: any[] = [];
  listaTagDominio: any[] = [];
  tagGeneraleSelezionato: any;
  tagPrimarioSelezionato: any;
  tagSecondariSelezionati: any;

  dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'descrizione',
    selectAllText: 'Seleziona tutti',
    unSelectAllText: 'Deseleziona tutti',
    allowSearchFilter: true
  };

  constructor(
    private tagService: TagService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {

    if (localStorage.getItem('listaTagFornitore')) {
      this.listaTagFornitore = JSON.parse(localStorage.getItem('listaTagFornitore'));
    }
    
    this.tagService.recuperaTagPublic('').subscribe(res => {
      this.listaTagDominio = res.tags;
    });
  }

  continua() {
    this.nextStep.emit();
  }

  indietro() {
    this.previousStep.emit();
  }

  selezionaTagGenerale(tag) {
    this.tagGeneraleSelezionato = tag;
    if (!tag.figli) {
      this.tagService.recuperaTagPublic(tag.id).subscribe(res => {
        if (res.tags.length > 0) {
          this.tagGeneraleSelezionato.figli = res.tags;
        } else {
          conferma(OgModalComponent ,this.modalService, { 
            modalOption: {size: 'md'},
            align: 'center', 
            button: 'Chiudi',
            message: "Non sono stati trovati tag primari selezionabili, per favore scegli un altro tag generale",
            hideBack: true
          });
          this.tagGeneraleSelezionato = undefined;
        }
      });
    }
  }

  selezionaTagPrimario(tag) {
    this.tagPrimarioSelezionato = tag;
    if (!tag.figli) {
      this.tagService.recuperaTagPublic(tag.id).subscribe(res => {
        if (res.tags.length > 0) {
          this.tagPrimarioSelezionato.figli = res.tags;
        } else {
          conferma(OgModalComponent ,this.modalService, { 
            modalOption: {size: 'md'},
            align: 'center', 
            button: 'Chiudi',
            message: "Non sono stati trovati tag secondari selezionabili, per favore scegli un altro tag primario",
            hideBack: true
          });
          this.tagPrimarioSelezionato = undefined;
        }
      });
    }
  }

  aggiungiTag() {
    // sottraggo i tag che sono già stati aggiunti in tabella dai tag selezionati
    const inserimenti = this.tagSecondariSelezionati.filter((tag) => !this.listaTagFornitore.find((tagFornitore) => tagFornitore.idTerzo === tag.id));
    
    if (inserimenti.length === 0) {
      conferma(OgModalComponent ,this.modalService, { 
        modalOption: {size: 'md'},
        align: 'center', 
        button: 'Chiudi',
        message: "I tag selezionati sono già stati associati, per favore scegli un altro tag",
        hideBack: true
      });
      this.tagSecondariSelezionati = undefined;
      return;
    } else {
      inserimenti.forEach((tagDiSecondoLivello: any) => {
        const rigaDaAggiungere = {
          descrizionePrimo: this.tagGeneraleSelezionato.descrizione,
          descrizioneSecondo: this.tagPrimarioSelezionato.descrizione,
          descrizioneTerzo: tagDiSecondoLivello.descrizione,
          idPrimo: this.tagGeneraleSelezionato.id,
          idSecondo: this.tagPrimarioSelezionato.id,
          idTerzo: tagDiSecondoLivello.id
        }

        this.listaTagFornitore.push(rigaDaAggiungere);
      });

      // aggiorno il localStorage
      localStorage.setItem('listaTagFornitore', JSON.stringify(this.listaTagFornitore));

      // resetto le select
      this.tagGeneraleSelezionato = undefined;
      this.tagPrimarioSelezionato = undefined;
      this.tagSecondariSelezionati = undefined;
    }
  }

  cancellaTag(tagDaCancellare) {
    conferma(OgModalComponent ,this.modalService, { 
      modalOption: {size: 'md'},
      align: 'center',
      button: 'Rimuovi',
      message: "Sei sicuro di voler rimuovere il tag?",
      hideBack: false
    }).then((result) => {
      if (result) {
        const filtered = [];

        // tolgo dalla lista il tag
        this.listaTagFornitore.forEach(tagFornitore => {
          if (tagFornitore.idTerzo !== tagDaCancellare.idTerzo) {
            filtered.push(tagFornitore);
          }
        });

        this.listaTagFornitore = filtered;

        // aggiorno il localStorage
        localStorage.setItem('listaTagFornitore', JSON.stringify(this.listaTagFornitore));
      }
    });
  }
}
