import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-control-error',
  template: `<p *ngFor="let error of _errors" class="help text-danger font-12">{{ error.text }}</p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent implements OnInit {
  _errors: string[];

  @Input() set errors(value: string[]) {
    this._errors = value;
      this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    // do nothing
  }
}
