export class NuovoFornitore {
  cap: string;
  citta: string;
  cittaResidenza: string;
  codiceFiscale: string;
  cognome: string;
  comuneNascita: string;
  dataNascita: Date;
  emailComunicazioni: any[];
  identitaFirmatarioOfferta: {
    nomeFile: string,
    tipologiaDoc?: string,
    stringBase64: string,
    mimeType: string
  };
  indirizzoResidenza: string;
  nazione: string;
  nome: string;
  partitaIva: string;
  pec: string;
  ragioneSociale: string;
  ruolo: string;
  scadenzaDocumentoFirmatario: Date;
  tagDomini: any[];
  telefono: string;
  via: string;
}
