import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NuovoFornitore } from 'src/app/shared/_model/nuovo-fornitore';
import { FornitoriService } from 'src/app/shared/_service/fornitori.service';
import { conferma } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { OgModalComponent } from 'src/app/shared/modals/og-modal/og-modal.component';
import { erroreDuranteIlSalvataggio } from 'src/app/shared/util';

@Component({
  selector: 'app-registrazione-fornitore',
  templateUrl: './registrazione-fornitore.component.html',
  styles: []
})
export class RegistrazioneFornitoreComponent implements OnInit {

  nuovoFornitore: NuovoFornitore = new NuovoFornitore();

  stepDettagli: boolean;
  stepTag: boolean;
  stepContatti: boolean;
  stepLegaleRappresentante: boolean;
  stepFine: boolean
  subscription: Subscription[] = [];

  stepsCompleated = {
    stepDettagli: false,
    stepTag: false,
    stepContatti: false,
    stepLegaleRappresentante: false,
    stepFine: false
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private fornitoriService: FornitoriService
  ) { }

  ngOnInit() {
    this.changeStep('stepDettagli') ;
  }

  stepCompleted(step: any) {
    localStorage.setItem(step, 'COMPLEATED');
    this.stepsCompleated[step] = true;
    this.changeStep(step);
  }

  stepBefore(step: any) {
    this.changeStep(step);
  }

  salvaNuovoFornitore() {
    this.nuovoFornitore = this.impostaNuovoFornitore();

    this.fornitoriService.saveAccreditamentoFornitore(this.nuovoFornitore).subscribe(() => {
      this.changeStep('stepFine');
    }, err => {
      const errorMessage = err.status === 400 ? err.error : "Si è verificato un errore durante la registrazione, per favore riprova più tardi.";
      erroreDuranteIlSalvataggio(OgModalComponent ,this.modalService, { 
        modalOption: {size: 'md'},
        align: 'center', 
        button: 'Chiudi',
        message: errorMessage,
        hideBack: true
      });
    });
  }

  isStepCompleated(step: any) {
    return !this.stepsCompleated[step];
  }

  changeStep(step: any) {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        step: step
      },
      queryParamsHandling: 'merge'
    }).then(()=>{
      this.activateRoute(step);
    });
  }

  activateRoute(step: any){
    this.stepDettagli = false;
    this.stepTag = false;
    this.stepContatti = false;
    this.stepLegaleRappresentante = false;
    this.stepFine = false;
    if (step == this.activatedRoute.snapshot.queryParamMap.get("step")){
      this[step]= true;
    } else {
      return false;
    }
  }

  impostaNuovoFornitore() {
    const dettaglioFornitore = JSON.parse(localStorage.getItem('dettaglioFornitore'));
    const listaTagFornitore = JSON.parse(localStorage.getItem('listaTagFornitore'));
    const contattiFornitore = JSON.parse(localStorage.getItem('contattiFornitore'));
    const legaleRappresentante = JSON.parse(localStorage.getItem('legaleRappresentante'));

    const body: NuovoFornitore = new NuovoFornitore();

    // dettaglioFornitore
    body.ragioneSociale = dettaglioFornitore.ragioneSociale;
    body.codiceFiscale = dettaglioFornitore.codiceFiscale;
    body.partitaIva = dettaglioFornitore.partitaIva;
    body.via = dettaglioFornitore.via;
    body.nazione = dettaglioFornitore.nazione;
    body.citta = dettaglioFornitore.citta;
    body.cap = dettaglioFornitore.cap;
    body.telefono = dettaglioFornitore.telefono;
    body.pec = dettaglioFornitore.pec;

    //listaTagFornitore
    body.tagDomini = [];

    listaTagFornitore.forEach((tag: any) => {
      body.tagDomini.push(tag.idTerzo);
    });

    // contattiFornitore
    body.emailComunicazioni = [];

    contattiFornitore.forEach((contatto: any) => {
      body.emailComunicazioni.push(contatto);
    });

    // legaleRappresentante
    body.identitaFirmatarioOfferta = {
      mimeType: '',
      stringBase64:'',
      nomeFile:''
    }
    
    body.nome = legaleRappresentante.nome;
    body.cognome = legaleRappresentante.cognome;
    body.ruolo = legaleRappresentante.ruolo;
    body.comuneNascita = legaleRappresentante.comuneNascita;
    body.dataNascita = legaleRappresentante.dataNascitaFirmatario;
    body.scadenzaDocumentoFirmatario = legaleRappresentante.scadenzaDocumentoFirmatario;
    body.cittaResidenza = legaleRappresentante.cittaResidenza;
    body.indirizzoResidenza = legaleRappresentante.indirizzoResidenza;
    body.identitaFirmatarioOfferta.nomeFile = legaleRappresentante.identitaFirmatarioOfferta.nomeFile;
    body.identitaFirmatarioOfferta.stringBase64 = legaleRappresentante.identitaFirmatarioOfferta.stringBase64;
    body.identitaFirmatarioOfferta.mimeType = legaleRappresentante.identitaFirmatarioOfferta.mimeType;

    return body;
  }

  apriModaleLogin() {
    conferma(this.modalService, 'Stai per per uscire da questa pagina. Perderai tutti i dati inseriti. Sei sicuro di voler uscire?', 'Conferma').then((ok: any) => {
      if (ok) {
        this.router.navigate(['']);
        localStorage.clear();
      }
    });
  }

  linkFineCompilazione() {
    this.router.navigate(['']);
    localStorage.clear();
  }
}
