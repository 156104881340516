import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-og',
  templateUrl: './sidebar-og.component.html'
})
export class SidebarOGComponent {

  constructor(private router: Router){ }

  navigateNewRdo(){
    this.router.navigate(['/'], {skipLocationChange: true}).then(()=>this.router.navigate(['/main/new-rdo']));
  }
}
