<ng-container>
  <div class="d-flex my-4">
    <div class="mr-auto">
      <h3 class="text-uppercase color-cprimary">Lista contatti</h3>
    </div>
    <div class="pl-3">
      <button class="btn btn-bg-full px-3" (click)="nuovoContatto()">NUOVO CONTATTO</button>
    </div>
  </div>

  <div class="color-cred">
    Inserire almeno un contatto per ogni ruolo: <b>Ufficio amministrativo, Referente commerciale, Ufficio gare e Ufficio gestine ordini.</b>
  </div>

  <table class="table table-hover my-4 tabellaConLista">
    <thead>
      <tr class="row">
        <th scope="col" class="border-bottom-0 col-3">Nome e cognome</th>
        <th scope="col" class="border-bottom-0 col-3">Ruolo</th>
        <th scope="col" class="border-bottom-0 col-3">E-mail</th>
        <th scope="col" class="border-bottom-0 col-2">Telefono</th>
        <th scope="col" class="border-bottom-0 col-1"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let contatto of contattiFornitore" class="row">
        <td class="col-3">{{contatto.nome}} {{contatto.cognome}}</td>
        <td class="col-3">{{contatto.descrizioneRuolo}}</td>
        <td class="col-3">{{contatto.email}}</td>
        <td class="col-2">{{contatto.telefono}}</td>

        <td class="text-right col-1">
          <button type="button" class="btn btn-no-btn ml-2" (click)="cancellaContatto(contatto)">
            <i class="fa fa-trash" title="Elimina contatto"></i>
          </button>
        </td>
      </tr>
      <tr *ngIf="contattiFornitore.length === 0">
        <td class="col-3" style="text-align: center;" colspan="3">Ancora nessun dato inserito. Per aggiungere un
          contatto cliccare il pulsante "NUOVO CONTATTO" in alto a destra</td>
      </tr>
    </tbody>
  </table>

  <div class="col mt-5">
    <small class="form-text text-muted">
      <b>NB</b> L’indirizzo e-mail del Referente RDO sarà utilizzato da Ospedal Grando per l’invio di ogni
      richiesta di offerta.
    </small>
  </div>
</ng-container>