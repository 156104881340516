<div  class="modal-header border-bottom-0 border-top-0">
  <h5 class="pt-4 pl-4">{{message}}</h5>
  <button type="button" class="close outline-none" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body border-bottom-0 border-top-0 mb-3" *ngIf="messageBody">
  <div class="pt-4 pl-4" [innerHTML]="messageBody"></div>
</div>

<div class="modal-footer border-bottom-0 border-top-0 px-4 pt-0">
  <div class="d-flex justify-content-start flex-row-reverse">
    <button type="button" class="btn btn-bg-full ml-4" (click)="modal.close(true)" >{{button}}</button>
    <button type="button" class="btn btn-bg-empty" (click)="modal.close()" >Annulla</button>
  </div>
</div>
