<div id="topLogin">
    <img src="assets/img/logoOG.png"/>
</div>

<form id="boxAutenticazione" #form="ngForm">
    <input type="text" maxlength="200" class="form-control font-12" placeholder="Partita IVA" [(ngModel)]="user.username" name="username" required autofocus/>
    <input type="password" class="form-control font-12" placeholder="PASSWORD" [(ngModel)]="user.password" name="password" required/>

    <button type="button" class="btn px-5 py-2 btn-bg-full" (click)="login()" [disabled]="form.invalid">LOGIN</button>

    <div id="recuperaPassword" (click)="openRecuperaPassword()">Recupera password</div>
    <!-- <div id="registrazioneFornitore" (click)="openRegistrazioneFornitore()">Sei un fornitore? Registrati!</div> -->
</form>
