
  <div class="form-group">
    <div class="row">

      <div class="col">
        <div>
          <label *ngIf="title"   [ngClass]="{'titleFileDownload': download, 'font-weight-bold': titleBold}">{{title}}<span class="txt-red" *ngIf="required || visualizzaRequired">*</span></label>
          <div class="input-group mb-3">
            <div class="d-flex align-items-center downloadIconContainerFile cursorPointer" *ngIf="download" (click)="downloadFile()">
              <i class="fas fa-file-download" title="Visualizza il documento"></i>
            </div>
            <div class="custom-file">
              <input  type="file"  accept="{{tipiFileToUpload}}"  class="custom-file-input" [id]="puntatore"  [name]="puntatore"  (change)="fileChange($event.target.files)" [disabled]="disabled"/>
              <label  class="custom-file-label" [for]="puntatore">{{ file?.nomeFile || 'Scegli file...'}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!required && file && eliminazione">
      <div class="col">
        <button class="btn btn-no-btn" (click)="fileDelete()" [disabled]="disabled">
          <i class="far fa-trash-alt" title="Elimina">
            <span class="titoloDentroFaFas">Elimina</span>
          </i>
        </button>
      </div>
    </div>
  </div>
