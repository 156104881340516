import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConsegnaCollaudo, Contabilita } from '../_model/consegna-collaudo';
import { Injectable } from "@angular/core";

@Injectable()
export class ConsegnaCollaudoService {
  constructor(private http : HttpClient) {}

  /**
   * Consegna collaudo specifica di una rdo e di una rdo fornitore
   * @param id id della consegna collaudo
   * @param idRdo id della rdo
   * @param idRdoFornitore id di rdo fornitore
   */
  readConsegnaCollaudo(id : number, idRdo : number, idRdoFornitore : number): Observable<ConsegnaCollaudo> {
    if(id) {
      return this.http.get<ConsegnaCollaudo>(environment.apiUrl + `consegnaCollaudo/read?id=${id}&idRdo=${idRdo}&idRdoFornitore=${idRdoFornitore}&lingua=${environment.lang}`);
    } else {
      return this.http.get<ConsegnaCollaudo>(environment.apiUrl + `consegnaCollaudo/read?idRdo=${idRdo}&lingua=${environment.lang}`);
    }
  }

  /**
   * Crea o aggiorna una consegna e collaudo
   * @param jsonIn consegna e collaudo
   */
  nuovaConsegnaCollaudo(jsonIn : Partial<ConsegnaCollaudo>): Observable<any> {
    return this.http.post(environment.apiUrl + "consegnaCollaudo/createUpdate?lingua=" + environment.lang, jsonIn);
  }

  /*
  * Ottieni le informazioni per un nuovo collaudo passando idRdo
  * @param idRdo id della rdo
  */
  datiPerNuovoCollaudo(idRdo: number): Observable<any>  {
    return this.http.get<any>(environment.apiUrl + `consegnaCollaudo/datiPerNuovoCollaudo?idRdo=${idRdo}&lingua=` + environment.lang);
  }

  /**
  * Crea o aggiorna un collaudo con i suoi dati
  * @param corpo
  */
  salvaCollaudo(corpo: Contabilita): Observable<any> {
    return this.http.post(environment.apiUrl + 'consegnaCollaudo/salvaCollaudo?lingua=' + environment.lang, corpo);
  }
  
  collaudiFiltrati(collaudoFiltroDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + "consegnaCollaudo/collaudiFiltrati?lingua=" + environment.lang, collaudoFiltroDto);
  }

  collaudiFiltratiSlim(collaudoFiltroDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + "consegnaCollaudo/collaudiFiltratiSlim?lingua=" + environment.lang, collaudoFiltroDto);
  }

  cancellaCollaudo(idCollaudo) {
    return this.http.delete(environment.apiUrl + `consegnaCollaudo/cancellaCollaudo?idCollaudo=${idCollaudo}`);
  }
}
