import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/_service/toast.service';
import { AuthService } from '../_service/auth.service';

@Component({
  selector: 'app-recupera-password',
  templateUrl: './recupera-password.component.html'
})
export class RecuperaPasswordComponent {

  constructor(private auth: AuthService, private router: Router, private toastService: ToastService) { }

  jsonIn = {
    username: ''
  };

  backToLogin() {
    this.router.navigate(['/auth/login']);
  }

  recuperaPassword() {
    this.auth.recuperaPassword(this.jsonIn.username).subscribe(() => {
      this.toastService.showSuccess('Abbiamo inviato le instruzioni per il recurero della password sulla casella mail collegata all\'utente inserito!', 5000);
      this.router.navigate(['/auth/login']);
    });
  }
}
