<div *ngIf="stepListaContatti" class="d-flex my-4">
  <div class="mr-auto">
    <h3 class="text-uppercase color-cprimary">Contatti</h3>
  </div>
  <div class="pl-3">
    <button class="btn btn-bg-empty px-3 mr-1" (click)="indietro()">INDIETRO</button>
    <button class="btn btn-bg-full px-3" (click)="continua()" [disabled]="contattiFornitore.length === 0">CONTINUA</button>
  </div>
</div>
<div class="contenutoButtonSelezionato">
  <app-lista-contatti *ngIf="stepListaContatti" [contattiFornitore]="contattiFornitore" (aggiungiContatto)="changeStep($event)"></app-lista-contatti>
  <app-nuovo-contatto *ngIf="!stepListaContatti" (tornaAllaLista)="changeStep($event)"></app-nuovo-contatto>
</div>