import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpRequest } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule as AngularCommonModule } from '@angular/common';

import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';

export function blockUIHttpModuleRequestFilter(req: HttpRequest<any>): boolean {
  return Boolean(req.url.match(/noBlock/g));
}

@NgModule({
  imports: [
    AngularCommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BlockUIModule.forRoot({
      delayStart: 150,
      delayStop: 500
    }),
    BlockUIHttpModule.forRoot({
      requestFilters: [blockUIHttpModuleRequestFilter]
    }),
  ],
  exports: [
    AngularCommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BlockUIModule,
    BlockUIHttpModule,
  ],
  providers: [

  ]
})
export class CoreModule { }
