<div class="d-flex flex-column justify-content-between h-100">

  <ul class="p-2 nav flex-column pt-4" id="menuAmministrazione">
    <li class="nav-item mb-4">
      <a class="nav-link" [routerLink]="['/main/home']" routerLinkActive="active-link">
        <i class="fas fa-home w-20px"></i>
        <span class="d-none d-xl-inline px-2">Dashboard</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="navigateNewRdo()">
        <i class="fas fa-wrench w-20px"></i>
        <span class="d-none d-xl-inline px-2">Nuovo RDO</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/main/lista-rdo']" routerLinkActive="active-link">
        <i class="fas fa-th-list w-20px"></i>
        <span class="d-none d-xl-inline px-2">Lista RDO</span>
      </a>
    </li>
    <li class="nav-item mb-4">
      <a class="nav-link" [routerLink]="['/main/fornitori']" routerLinkActive="active-link">
        <i class="fas fa-user-tie w-20px"></i>
        <span class="d-none d-xl-inline px-2">Lista fornitori</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/main/vendor-rating']" routerLinkActive="active-link">
        <i class="fas fa-comment-dots w-20px"></i>
        <span class="d-none d-xl-inline px-2">Vendor rating</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/main/faq']" routerLinkActive="active-link">
        <i class="fas fa-question w-20px"></i>
        <span class="d-none d-xl-inline px-2">F.A.Q.</span>
      </a>
    </li>
    <li class="nav-item mb-4">
      <a class="nav-link" [routerLink]="['/main/moderazione-quesiti']" routerLinkActive="active-link">
        <i class="fas fa-comments  w-20px"></i>
        <span class="d-none d-xl-inline px-2">Moderazione quesiti</span>
      </a>
    </li>
    <li class="nav-item mb-4">
      <a class="nav-link" [routerLink]="['/main/gestione-fatture-og']" routerLinkActive="active-link">
        <i class="far fa-money-bill-alt  w-20px"></i>
        <span class="d-none d-xl-inline px-2">Gestione Fatture OG</span>
      </a>
    </li>
  </ul>

  <app-impostazioni-sidebar></app-impostazioni-sidebar>

</div>