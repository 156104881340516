import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private http : HttpClient) { }

  /**
   * recupero la lista delle radici (id = '') oppure tutti i nodi figli di un padre (id = n)
   * 
   * @param id eventuale id del padre
   */
  recuperaTag(idPadre: any): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}tags/figli/${idPadre}?lingua=${environment.lang}`);
  }

  /**
   * versione public del servizio recuperaTag
   * 
   * @param id eventuale id del padre
   */
  recuperaTagPublic(idPadre: any): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}tags/public/figli/${idPadre}?lingua=${environment.lang}`);
  }

  /**
   * aggiungo una radice (id = '') oppure un nodo figlio (id = n)
   * 
   * @param id eventuale id del padre
   */
  aggiungiTag(tag: any): Observable<any> {
    const body = { 
      descrizione: tag.descrizione
    }
    
    return this.http.post<any>(`${environment.apiUrl}tags/${tag.id}`, body);
  }

  /**
   * modifica la descrizione di un tag
   * 
   * @param id del tag in modifica
   */
  modificaTag(tag: any): Observable<any> {
    const body = {
      descrizione: tag.descrizione
    }
    
    return this.http.put<any>(`${environment.apiUrl}tags/${tag.id}`, body);
  }

  /**
   * cancellazione logica di un tag
   * 
   * @param idTag id del tag da cancellare
   */
  cancellaTag(idTag: number): Observable<any> {
    
    return this.http.delete<any>(`${environment.apiUrl}tags/${idTag}`);
  }

  /**
   * recupero la lista dei tag
   */
  recuperaElencoTagFornitore(idFornitore: number): Observable<any> {   
    return this.http.get<any>(`${environment.apiUrl}tags/fornitore/${idFornitore}/?lingua=${environment.lang}`);
  }

  /**
   * Inserisce il tag sul fornitore
   */
  aggiungiTagFornitore(idFornitore: number, idTag: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}tags/${idTag}/fornitore/${idFornitore}`, {});
  }

  /**
   * Rimuove il tag dal fornitore
   */
  rimuoviTagFornitore(idFornitore: number, idTag: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}tags/${idTag}/fornitore/${idFornitore}`);
  }
}