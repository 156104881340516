/**
 * IMPORT CORE DI ANGULAR
 */

import { NgModule } from '@angular/core';
import { TokenInterceptor } from './_interceptor/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { AuthService } from './_service/auth.service';
import { AuthLoginComponent } from './login/login.component';
import { AuthLogoutComponent } from './logout/logout.component';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth.routing';
import { RecuperaPasswordComponent } from './recupera-password/recupera-password.component';
import { ModificaPasswordComponent } from './modifica-password/modifica-password.component';
import { RegistrazioneFornitoreComponent } from './registrazione-fornitore/registrazione-fornitore.component';
import { DettagliComponent } from './registrazione-fornitore/dettagli/dettagli.component';
import { TagComponent } from './registrazione-fornitore/tag/tag.component';
import { ContattiComponent } from './registrazione-fornitore/contatti/contatti.component';
import { LegaleRappresentanteComponent } from './registrazione-fornitore/legale-rappresentante/legale-rappresentante.component';
import { ConfirmModalComponent } from '../shared/modals/confirm-modal/confirm-modal.component';
import { NuovoContattoComponent } from './registrazione-fornitore/contatti/nuovo-contatto/nuovo-contatto.component';
import { ListaContattiComponent } from './registrazione-fornitore/contatti/lista-contatti/lista-contatti.component';

@NgModule({
  declarations: [
    AuthLoginComponent,
    RecuperaPasswordComponent,
    AuthLogoutComponent,
    ModificaPasswordComponent,
    RegistrazioneFornitoreComponent,
    DettagliComponent,
    TagComponent,
    ContattiComponent,
    LegaleRappresentanteComponent,
    NuovoContattoComponent,
    ListaContattiComponent
  ],
  imports: [
    SharedModule,
    AuthRoutingModule,
  ],
  exports: [
    
  ],
  entryComponents: [ConfirmModalComponent],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ]
})
export class AuthModule { }
