<div class="d-flex my-4">
  <div class="mr-auto">
    <h5 class="text-uppercase color-cprimary">Dati anagrafici del firmatario dell'offerta</h5>
  </div>
  <div class="pl-3">
    <button class="btn btn-bg-empty px-3 mr-1" (click)="indietro()">INDIETRO</button>
    <button
      class="btn btn-bg-full px-3"
      (click)="salva()"
      [disabled]="form.invalid || !legaleRappresentante.identitaFirmatarioOfferta.stringBase64"
    >Salva</button>
  </div>
</div>

<form #form="ngForm" id="profiloFornitoreForm">
  <div class="row mb-3">
    <div class="col-md-4">
      <div class="form-group">
        <label>Nome<span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="nomeLegale" name="nomeLegale" [(ngModel)]="legaleRappresentante.nome" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Cognome<span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="cognome" name="cognome" [(ngModel)]="legaleRappresentante.cognome" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Ruolo<span class="txt-red">*</span></label>
        <select class="form-control" [(ngModel)]="legaleRappresentante.ruolo" id="ruolo" name="ruolo" required>
          <option [ngValue]="undefined">Seleziona un ruolo</option>
          <option *ngFor="let ruolo of ruoli" [ngValue]="ruolo.name">{{ruolo.name}}</option>
        </select>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Luogo di nascita<span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="comuneNascita" name="comuneNascita" [(ngModel)]="legaleRappresentante.comuneNascita" required/>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Data di nascita<span class="txt-red">*</span></label>
        <div class="input-group">
          <input type="date" class="form-control cursorPointer" id="dataNascitaFirmatario"
            name="dataNascitaFirmatario" required [(ngModel)]="dataNascitaFirmatario" (change)="dataNascitaChanged()" format="DD/MM/YYYY" />
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-12">
      <h5 class="text-uppercase color-cprimary">Documenti</h5>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-8">
      <app-og-upload-file [required]='true' [file]="legaleRappresentante.identitaFirmatarioOfferta" [title]="'Documento d\'identità'" (eventFileChange)="fileChange($event)"></app-og-upload-file>
    </div>
    
    <div class="col-md-4">
      <div class="form-group">
        <label>Data scadenza documento d'identità <span class="txt-red">*</span></label>
        <div class="input-group">
          <input type="date"  class="form-control cursorPointer" id="scadenzaDocumentoFirmatario"
            name="scadenzaDocumentoFirmatario" required [(ngModel)]="scadenzaDocumentoFirmatario" (change)="dataScadenzaChanged()" format="DD/MM/YYYY" />
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-12">
      <h5 class="text-uppercase color-cprimary">Recapito del firmatario dell'offerta</h5>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-4">
      <div class="form-group">
        <label>Città di residenza<span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="cittaResidenza" name="cittaResidenza" [(ngModel)]="legaleRappresentante.cittaResidenza" required/>
      </div>
    </div>

    <div class="col-md-8">
      <div class="form-group">
        <label>Indirizzo di residenza<span class="txt-red">*</span></label>
        <input type="text" maxlength="200" class="form-control" id="indirizzoResidenza" name="indirizzoResidenza" [(ngModel)]="legaleRappresentante.indirizzoResidenza" required/>
      </div>
    </div>
  </div>
</form>
