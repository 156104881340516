import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://0e8d5407f53faf614aafd8f3fea06a45@o4507090121916416.ingest.de.sentry.io/4507340301926480",
  release: "RELEASE_607ae79689a84d810688a5fee521fa5686847ed8",
  environment: "production",
  integrations: [],
});

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
