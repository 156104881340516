import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Rdo } from 'src/app/shared/_model/rdo';
import { FileData, FileUpload } from 'src/app/shared/_model/common';
import { NomLavDto } from '../_query/fornitore';
import { Fornitore } from '../_model/fornitore';
import { DichiarazioneLavori, DichiarazioneUnica } from '../_model/common';
import { Injectable } from "@angular/core";

@Injectable()
export class RdoService {
  constructor(private http: HttpClient) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    })
  };


  /**************************
  *********** DATI **********
  **************************/
  listaRdo(): Observable<any> {
    const params = new HttpParams()
      .set("lingua", environment.lang)

    return this.http.get(environment.apiUrl + "rdo/list/" + localStorage.getItem("codiceArea"), { params }).pipe(map((res: any) => { return res; }));
  }

  listaRdoOg(): Observable<any> {
    const params = new HttpParams()
      .set("lingua", environment.lang)

    return this.http.get(environment.apiUrl + "rdo/listOg/" + localStorage.getItem("codiceArea"), { params }).pipe(map((res: any) => { return res; }));
  }

  /**
   * Lista RDO filtrata per codiceStato
   * @param codiceStato codice dello stato in cui si trova l'RDO
   */
  readByStato(codiceStato: string): Observable<Rdo[]> {
    return this.http.get<Rdo[]>(environment.apiUrl + `rdo/list/read-by-stato/${codiceStato}?lingua=${environment.lang}`);
  }

  nuovoRdo(jsonIn: Rdo): Observable<any> {
    return this.http.post(environment.apiUrl + 'rdo/create?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  updateRdo(jsonIn: Rdo, invioMail: boolean): Observable<any> {
    return this.http.put(environment.apiUrl + 'rdo/update?invioMail=' + invioMail + '&lingua=' + environment.lang, jsonIn, { responseType: 'json' }).pipe(map((response: any) => response));
  }
/**
 * Ottieni una RDO
 * @param id Id della RDO
 */
  dettaglioRdo(id: number | string): Observable<Rdo> {
    id = typeof id === 'string'? parseInt(id): id;
    return this.http.get<Rdo>(environment.apiUrl + `rdo/read/${id}?lingua=${environment.lang}`);
  }

  /**
 * Ottieni l'ultima RDO inserita
 * @param codArea codice area RDO
 */
ultimoRdo(): Observable<Rdo> {
  return this.http.get<Rdo>(environment.apiUrl + 'rdo/read/get-last-rdo/'+ localStorage.getItem("codiceArea") +'?lingua='+ environment.lang);
}


/**
 * Duplica  una rdo
 * @param idRdo id della Rdo da duplicare
 * @param isConfermaOfferta boolean se conferma offerta
 */
duplicaRdo(idRdo: number, isConfermaOfferta: boolean):Observable<any> {
  return this.http.put<any>(environment.apiUrl + 'rdo/duplica/'+ idRdo +'?confermaOfferta='+ isConfermaOfferta, { responseType: 'json' }).pipe(map((response: any) => response));

}

  /**
   * Lista di documenti amministrativi relativi alla RDO
   * @param id id della RDO
   */
  dettaglioFileRdo(idRdo: number): Observable<FileData[]> {
    return this.http.get<FileData[]>(environment.apiUrl + 'rdo/file/list/' + idRdo + '?lingua=' + environment.lang);
  }

  filtraDocRdoGenerici(jsonIn: any) {
    return this.http.post(environment.apiUrl + 'rdo/file/docRdoGenericiFiltra', jsonIn).pipe(map((response: any) => response));
  }

  cancellaDocumentoGenericoOG(idDocumento:number) {
    return this.http.delete(environment.apiUrl + 'rdo/file/cancellaDocumentoGenericoOG/' + idDocumento).pipe(map((response: any) => response));
  }

  uploadDocumentiGenericiOG(body: any) {
    return this.http.post(environment.apiUrl + 'rdo/files/caricaDocumentiGenericiOG', body).pipe(map((response: any) => response));
  }

  listaDocRdoGenerici(tipoDoc:string) {
    return this.http.get(environment.apiUrl + 'rdo/file/listaDocRdoGenerici/' + tipoDoc + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }
  listaTipoDocRdoGenerici(tipoDoc: string){
    return this.http.get(environment.apiUrl + 'rdo/file/listaTipoDocRdoGenerici/' + tipoDoc + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }
  downloadFile(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo/file/download/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  downloadDocGenerico(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo/file/downloadDocGenerico/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  deleteRdo(jsonIn: number) {
    return this.http.delete(environment.apiUrl + 'rdo/delete/' + jsonIn + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  deleteFileRdo(jsonIn: number){
    return this.http.delete(environment.apiUrl + 'rdo/file/delete/' + jsonIn +'?lingua=' + environment.lang, {responseType: 'text'}).pipe(map((response: any) => response));
  }
  insertUpdateFile(jsonIn: FileUpload[]): Observable<any> {
    return this.http.post(environment.apiUrl + 'rdo/files/update?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }


  /********************************
  *********** CAPITOLATO **********
  ********************************/
  nuovoTitoloCapitolato(jsonIn): Observable<any> {
    const params = new HttpParams()
      .set("idRdo", jsonIn.idRdo)
      .set("titolo", jsonIn.titolo);

    return this.http.post(environment.apiUrl + 'capitolato/titolo', jsonIn, { params }).pipe(map((response: any) => response));
  }

  updateTitoloCapitolato(jsonIn): Observable<any> {
    return this.http.put(environment.apiUrl + 'capitolato/titolo/' + jsonIn.id + '?idRdo=' + jsonIn.rdoId + '&titolo=' + jsonIn.titoloGruppo, {}).pipe(map((response: any) => response));
  }

  dettaglioCapitolato(idRdo: number) {
    return this.http.get(environment.apiUrl + 'capitolato/' + idRdo + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  deleteTitoloCapitolato(jsonIn) {
    return this.http.delete(environment.apiUrl + 'capitolato/titolo/' + jsonIn.id + '?idRdo=' + jsonIn.rdoId).pipe(map((response: any) => response));
  }

  nuovaVoceCapitolato(jsonIn): Observable<any> {
    const params = new HttpParams()
      .set("voce", jsonIn.voce);

    return this.http.post(environment.apiUrl + 'capitolato/titolo/' + jsonIn.idTitolo + '/voce', jsonIn, { params }).pipe(map((response: any) => response));
  }

  updateVoceCapitolato(jsonIn): Observable<any> {
    const params = new HttpParams()
      .set("voce", jsonIn.voce);

    return this.http.put(environment.apiUrl + 'capitolato/titolo/' + jsonIn.idTitolo + '/voce/' + jsonIn.idVoce, jsonIn, { params }).pipe(map((response: any) => response));
  }

  deleteVoceCapitolato(idVoce: number) {
    return this.http.delete(environment.apiUrl + 'capitolato/voce/' + idVoce, { responseType: 'text' }).pipe(map((response: any) => response));
  }


  /**************************************
  *********** SCELTA FORNITORI **********
  **************************************/
  insertFornitoriScelti(jsonIn, idRdo): Observable<any> {
    return this.http.put(environment.apiUrl + 'rdo/' + idRdo + '/fornitori?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }
  
  /**
   * Lista dei fornitori scelti nella RDO.
   * @param id id della RDO
   */
  getFornitoriRDO(id: number | string): Observable<Fornitore[]>{
    id = typeof id === 'string'? parseInt(id): id;
    return this.http.get<Fornitore[]>(environment.apiUrl + `rdo/${id}/fornitori/list?lingua=${environment.lang}`);
  }

  /******************************
  ******* TEMPLATE html e PDF **********
  ******************************/
  getHtmlOfTemplateRDO(idRdo: number, codiceTemplate: string) {
    return this.http.get(environment.apiUrl + 'template/ospedalgrando/html/' + codiceTemplate + '?idRdo=' + idRdo + '&lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  downloadPDFog(idRdo: number, codiceTemplate: string) {
    return this.http.get(environment.apiUrl + 'template/ospedalgrando/pdf/' + codiceTemplate + '?idRdo=' + idRdo + '&lingua=' + environment.lang, { responseType: 'blob', observe: 'response'});
  }

  /**********************************************************
  ********Descrizione Particolareggiata Controller********** 
  **********************************************************/

  //Crea una descrizione particolareggiata relativa ad un capitolato.
  rispondeCapitolato(jsonIn) {
    return this.http.post(environment.apiUrl + 'descriz-part/create' + '?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  //Ottieni una descrizione particolareggiata tramite id capitolato.
  descrizioneParticolareggiataByIdCapitolato(idCapitolato: any) {
    return this.http.get(environment.apiUrl + 'descriz-part/read-by-capitolato/' + idCapitolato + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  /********************************************
  *********** PUNTO PUNTO CONTROLLER ********** 
  ********************************************/
  //Crea una dichiarazione punto punto;
  createDicPuntoP(jsonIn) {
    return this.http.post(environment.apiUrl + 'dichiaraz-pp/create' + '?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  //Ottieni una dichiarazione punto punto tramite id rdo;
  getDicPuntoP(idRdo: any) {
    return this.http.get(environment.apiUrl + 'dichiaraz-pp/read-by-rdo-fornitore/' + idRdo + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  //Modifica una dichiarazione punto punto esistente;
  updateDicPuntoP(jsonIn) {
    return this.http.put(environment.apiUrl + 'dichiaraz-pp/update' + '?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  /****************************************************
  *********** Dichiarazione Unica Controller ********** 
  ****************************************************/
  /**
   * Crea una dichiarazione unica;
   */ 
  createDicUnica(query: DichiarazioneUnica): Observable<DichiarazioneUnica> {
    return this.http.post<DichiarazioneUnica>(`${environment.apiUrl}dichiaraz-unica/create?lingua=${environment.lang}`, query);
  }

  /**
   * Ottieni una dichiarazione unica tramite id rdo fornitore
   * @param idRdo 
   */
  getDicUnica(idRdo: number): Observable<DichiarazioneUnica> {
    return this.http.get<DichiarazioneUnica>(`${environment.apiUrl}dichiaraz-unica/read-by-rdo-fornitore/${idRdo}?lingua=${environment.lang}`);
  }

  //Modifica una dichiarazione unica esistente;
  updateDicUnica(query: DichiarazioneUnica): Observable<DichiarazioneUnica> {
    return this.http.put<DichiarazioneUnica>(`${environment.apiUrl}dichiaraz-unica/update?lingua=${environment.lang}`, query);
  }

  /****************************************************
  *********** Nominativi Lavori Controller ************ 
  ****************************************************/
  //Crea un doc nominativi lavori;
  createNominativoL(jsonIn: NomLavDto): Observable<NomLavDto> {
    return this.http.post<NomLavDto>(environment.apiUrl + 'nominativi-lavori/create' + '?lingua=' + environment.lang, jsonIn);
  }

  //Ottieni un doc nominativi lavori tramite id rdo;
  getNominativoL(idRdo: number): Observable<NomLavDto> {
    return this.http.get<NomLavDto>(environment.apiUrl + 'nominativi-lavori/read-by-rdo-fornitore/' + idRdo + '?lingua=' + environment.lang);
  }

  //Modifica un doc nominativi lavori esistente;
  updateNominativoL(jsonIn: NomLavDto): Observable<NomLavDto> {
    return this.http.put<NomLavDto>(environment.apiUrl + 'nominativi-lavori/update' + '?lingua=' + environment.lang, jsonIn);
  }


  /****************************************************
*********** Dichiarazione Lavori Controller ********** 
****************************************************/

  //Crea una dichiarazione lavori;
  createDicLavori(jsonIn) {
    return this.http.post(environment.apiUrl + 'offerta-economica/dichiaraz-lavori/create' + '?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  //Ottieni una dichiarazione lavori id;
  getDicLavori(id: any) {
    return this.http.get(environment.apiUrl + 'offerta-economica/dichiaraz-lavori/read/' + id + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  //Ottieni una dichiarazione lavori idOffertaEconomica;
  /**
   * Ottieni una dichiarazione lavori idOffertaEconomica
   * @param idOffertaEconomica 
   */
  getDicLavoriByIdOffertaEconomica(idOffertaEconomica: number): Observable<DichiarazioneLavori> {
    return this.http.get<DichiarazioneLavori>(`${environment.apiUrl}offerta-economica/dichiaraz-lavori/read-by-off-economica/${idOffertaEconomica}?lingua=${environment.lang}`);
  }

  //Modifica un doc nominativi lavori esistente;
  updateDicLavori(jsonIn) {
    return this.http.put(environment.apiUrl + 'offerta-economica/dichiaraz-lavori/update' + '?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

}
