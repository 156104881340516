import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OpenTelemetryService } from './open-telemetry.service';

@Injectable({ providedIn: 'root' })
export class LogService {
	constructor(private openTelemetryService: OpenTelemetryService) {}

	/*
	 * 0 -> nessun log
	 * 1 -> solo errori
	 * 2 -> errori e warning
	 * 3 -> errori, warning e info
	 * 4 -> errori, warning, info e debug
	 */
	livelloLogOpenTelemetry = 0;

	get timestamp() {
		return new DatePipe('en-US').transform(new Date(), 'HH:mm:ss');
	}

	inizializza() {
		const debug = console.debug;
		const stylesDebug = `background: CornflowerBlue; border-radius: 3px; color: white; font-weight: bold; padding: 2px 5px`;
		console.debug = (...args) => {
			debug('%c' + this.timestamp, stylesDebug, ...args);
			this.gestisciLog('debug', ...args);
		};

		const log = console.log;
		const stylesLog = `background: green; border-radius: 3px; color: white; font-weight: bold; padding: 2px 5px`;
		console.log = (...args) => {
			log('%c' + this.timestamp, stylesLog, ...args);
			this.gestisciLog('info', ...args);
		};

		const warn = console.warn.bind(console);
		const stylesWarn = `background: gold; border-radius: 3px; color: white; font-weight: bold; padding: 2px 5px`;
		console.warn = (...args) => {
			warn('%c' + this.timestamp, stylesWarn, ...args);
			this.gestisciLog('warn', ...args);
		};

		const error = console.error.bind(console);
		const stylesError = `background: red; border-radius: 3px; color: white; font-weight: bold; padding: 2px 5px`;
		console.error = (...args) => {
			error('%c' + this.timestamp, stylesError, ...args);
			this.gestisciLog('error', ...args);
		};
	}

	/**
	 * Abilita il log su OpenTelemetry di tutti i console.log() e degli errori applicativi
	 * @param livelloLog -> il livello di log minimo da abilitare
	 * @returns
	 */
	abilitaLogOpenTelemetry(livelloLog: 'debug' | 'info' | 'warn' | 'error') {
		if (!this.openTelemetryService.logger) {
			console.error('OpenTelemetry non inizializzato, chiamare prima inizializza() sul service OpenTelemetryService');
			return;
		}
		switch (livelloLog) {
			case 'debug':
				this.livelloLogOpenTelemetry = 4;
				break;
			case 'info':
				this.livelloLogOpenTelemetry = 3;
				break;
			case 'warn':
				this.livelloLogOpenTelemetry = 2;
				break;
			case 'error':
				this.livelloLogOpenTelemetry = 1;
				break;
		}
	}

	gestisciLog(tipoLog: 'debug' | 'info' | 'warn' | 'error', ...args: any) {
		if (this.openTelemetryService.logger) {
			switch (tipoLog) {
				case 'debug':
					if (this.livelloLogOpenTelemetry >= 4) {
						this.openTelemetryService.log(tipoLog, ...args);
					}
					break;
				case 'info':
					if (this.livelloLogOpenTelemetry >= 3) {
						this.openTelemetryService.log(tipoLog, ...args);
					}
					break;
				case 'warn':
					if (this.livelloLogOpenTelemetry >= 2) {
						this.openTelemetryService.log(tipoLog, ...args);
					}
					break;
				case 'error':
					if (this.livelloLogOpenTelemetry >= 1) {
						this.openTelemetryService.log(tipoLog, ...args);
					}
					break;
				default:
					break;
			}
		}
	}
}
