<style>
  .paddingButtonA{
    padding: 0.5rem 1rem !important;
  }
</style>

<div class="d-flex flex-column justify-content-between h-100">

  <ul class="p-2 nav flex-column pt-4" id="menuFornitori">

    <li class="nav-item mb-2">
      <button class="btn btn-no-btn text-light text-left paddingButtonA" [routerLink]="['/fornitori/home']" routerLinkActive="active-link" [disabled]="notMissingInfoData">
        <i class="fas fa-home w-20px"></i>
        <span class="d-none d-xl-inline px-2">Dashboard</span>
      </button>
    </li>

    <li class="nav-item mb-2">
      <button class="btn btn-no-btn text-light text-left paddingButtonA" [routerLink]="['/fornitori/lista-rdo']" routerLinkActive="active-link" [disabled]="notMissingInfoData">
        <i class="fas fa-th-list w-20px"></i>
        <span class="d-none d-xl-inline px-2">Lista RDO</span>
      </button>
    </li>
    <li class="nav-item mb-2">
      <button class="btn btn-no-btn text-light text-left paddingButtonA" [routerLink]="['/fornitori/configurazioni']" routerLinkActive="active-link">
        <i class="fas fa-user-tie w-20px"></i>
        <span class="d-none d-xl-inline px-2">Informazioni aziendali</span>
      </button>
    </li>
    <li class="nav-item mb-2">
      <button class="btn btn-no-btn text-light text-left paddingButtonA" [routerLink]="['/fornitori/faq']" routerLinkActive="active-link">
        <i class="fas fa-comments w-20px"></i>
        <span class="d-none d-xl-inline px-2">F.A.Q.</span>
      </button>
    </li>
  </ul>
</div>