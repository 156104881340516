import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dettagli',
  templateUrl: './dettagli.component.html',
  styles: []
})
export class DettagliComponent implements OnInit {

  @ViewChild('privacyContent') privacyModal: any;
  @Output() nextStep = new EventEmitter<void>();

  dettaglioFornitore: any = {};
  nascondiEsito = true;

  constructor(private modalService: NgbModal) { }

  ngAfterViewInit() {
    // mostro il modale sulla privacy solamente al primo accesso
    if (localStorage.getItem('privacy') !== 'true') {
      this.openModal(this.privacyModal);
    }
  }

  ngOnInit() {
    if (localStorage.getItem('dettaglioFornitore')) {
      this.dettaglioFornitore = JSON.parse(localStorage.getItem('dettaglioFornitore'));
    }
  }

  openModal(privacyModal) {
    this.modalService.open(privacyModal, { 
      scrollable: true, 
      size: "lg" ,
      backdrop: 'static'
    }).result.then(() => localStorage.setItem('privacy', 'true'));
  }

  continua() {
    localStorage.setItem('dettaglioFornitore', JSON.stringify(this.dettaglioFornitore));

    this.nextStep.emit();
  }
}
