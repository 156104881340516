import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FornitoriService } from 'src/app/shared/_service/fornitori.service';
import { SessionService } from 'src/app/shared/_service/session.service';

@Component({
  selector: 'app-sidebar-fornitori',
  templateUrl: './sidebar-fornitori.component.html'
})
export class SidebarFornitoriComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private service: FornitoriService
    ) { }

  notMissingInfoData = true;

  ngOnInit() {
    const key = this.sessionService.getLocal('ky_');
    if ( key && key === 4754398562150008 ) {
      this.notMissingInfoData = false;
    }
  }

}
