import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOption } from '../../util';

@Component({
  selector: 'app-og-modal',
  templateUrl: './og-modal.component.html'
})
export class OgModalComponent implements OnInit {

  @Input() option: ModalOption;

  constructor(private _modal: NgbActiveModal) { }

  ngOnInit() {
    // do nothing
  }
  close(val): void {  this._modal.close(val) }
}