import { Type } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export interface ModalOption {
  modalOption: NgbModalOptions,
  align: 'center' | 'start' | 'end';
  message: string;
  button?: string;
  backButton?: string;
  hideBack?: boolean;
}

export function conferma<T>(t: Type<T>, modalService: NgbModal, option: ModalOption): Promise<boolean> {
  const modal = modalService.open(t, option.modalOption);
  modal.componentInstance.option = option;
  return modal.result;
}

export function erroreDuranteIlSalvataggio<T>(t: Type<T>, modalService: NgbModal, option: ModalOption): Promise<boolean> {
  const modal = modalService.open(t, option.modalOption);
  modal.componentInstance.option = option;
  return modal.result;
}

export function download(data: any, nomeFile = "unknow", ext = null): void {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(data);
  if(ext){
    a.setAttribute('download', nomeFile+"."+ext);
  } else{
    a.setAttribute('download', nomeFile);
  }
  document.body.appendChild(a);
  a.click();
  a.remove();
}