import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class FaqService {
  constructor(private http: HttpClient) { }

  //ritorna tutte le faq
  getListFaq(): Observable<any> {
    return this.http.post(environment.apiUrl + 'faq/list', null).pipe(map((response: any) => response));
  }


  //crea una faq
  createFaq(jsonIn: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'faq/createUpdate', jsonIn).pipe(map((response: any) => response));
  }

  //cancella una faq
  deleteFaq(idFaq: any): Observable<any> {
    return this.http.delete(environment.apiUrl + 'faq/delete/'+idFaq).pipe(map((response: any) => response));
  }

  //ritorna una faq
  getFaq(idFaq: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'faq/read/'+idFaq).pipe(map((response: any) => response));
  }

}
