
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeit from '@angular/common/locales/it';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HttpErrorsInterceptor } from './shared/_interceptor/http-errors.interceptor';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/_guard/auth.guard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FornitoriGuard } from './auth/_guard/fornitori.guard';
import { MainGuard } from './auth/_guard/main.guard';
import { OspedaleGuard } from './auth/_guard/ospedale.guard';
import { MainComponent } from './main/main.component';
import { UserRedirectGuard } from './auth/_guard/user-redirect.guard';
import { PaginaNonTrovataComponent } from './main/pagina-non-trovata/pagina-non-trovata.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagModalComponent } from './shared/_component/tag-modal/tag-modal.component';
import { TagModificaModalComponent } from './shared/_component/tag-modifica-modal/tag-modifica-modal.component';
import * as Sentry from "@sentry/angular";
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

registerLocaleData(localeit, 'it');

const routes: Routes = [
  /* Qualunque rotta punta al componente Main e passa prima dall' auth guard */
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      /* La rotta vuota viene reindirizzata al modulo dell'utente registrato: main, fornitori, ospedale */
      {
        path: '',
        canActivate: [UserRedirectGuard],
        children: []
      },
      {
        path: 'main',
        canActivate: [MainGuard],
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
      },
      {
        path: 'fornitori',
        canActivate: [FornitoriGuard],
        loadChildren: () => import('./fornitori/fornitori.module').then(m => m.FornitoriModule)
      },
      {
        path: 'ospedale',
        canActivate: [OspedaleGuard],
        loadChildren: () => import('./ospedale/ospedale.module').then(m => m.OspedaleModule)
      },
      { path: '404', component: PaginaNonTrovataComponent },
    ]
  },
  /* Qualsasi rotta che non matcha redirige a /'' (MainComponent) /404 (PaginaNonTrovataComponent) */
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    AuthModule,
    BrowserModule,
    NgbRatingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuard,
    MainGuard,
    FornitoriGuard,
    OspedaleGuard,
    UserRedirectGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [TagModalComponent, TagModificaModalComponent]
})
export class AppModule { }
