import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impostazioni-sidebar',
  templateUrl: './impostazioni-sidebar.component.html',
  styles: []
})
export class ImpostazioniSidebarComponent implements OnInit {

  constructor() {
    // do nothing
  }

  open = false;
  ngOnInit() {
    // do nothing
  }

  changeOpen() {
    this.open = !this.open;
  }
}
