import { Component } from '@angular/core';

@Component({
  selector: 'app-pagina-non-trovata',
  templateUrl: './pagina-non-trovata.component.html'
})
export class PaginaNonTrovataComponent {

  constructor() {
    // do nothing
  }
}
