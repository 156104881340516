import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient) { }

  // Download zip visioni di un rdo-fornitore
  downloadZipVisioniRdoFornitore(idRdoFornitore: number) : Observable<any>{
    return this.http.get(environment.apiUrl + 'template/file/download/visioni/fornitore/zip/' + idRdoFornitore + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  // Download zip visioni di un rdo
  downloadZipVisioniRdo(idRdo: number) : Observable<any>{
    return this.http.get(environment.apiUrl + 'template/file/download/visioni/zip/' + idRdo + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  // Download zip ordine di un rdo di un fornitore
  downloadZipOrdine(idOrdine: number, idRdoFornitore: number) : Observable<any>{
    return this.http.get(`${environment.apiUrl}template/file/download/zip/ordineRdo?idOrdine=${idOrdine}&idRdoFornitore=${idRdoFornitore}&lingua=${environment.lang}`, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  // Download html rdo-fornitore con codice tmpl
  downloadHtmlFornitore(idRdo:number, idRdoFornitore: number, codiceHtml: string) : Observable<any>{
    return this.http.get(`${environment.apiUrl}template/fornitore/html/${codiceHtml}/${idRdoFornitore}?lingua=${environment.lang}&idRdo=${idRdo}`, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  //pdf rdo-fornitore
  downloadPdfFornitore(idRdo:number, codiceTemplate: string, idRdoFornitore: number): Observable<any> {
    return this.http.get(environment.apiUrl + `template/fornitore/pdf/${codiceTemplate}/${idRdoFornitore}?lingua=${environment.lang}&idRdo=${idRdo}`, { responseType: 'blob'});
  }
    //pdf rdo-fornitore
    downloadPdfFornitoreConNomeFile(idRdo:number, codiceTemplate: string, idRdoFornitore: number): Observable<any> {
      return this.http.get(environment.apiUrl + `template/fornitore/pdf/${codiceTemplate}/${idRdoFornitore}?lingua=${environment.lang}&idRdo=${idRdo}`, { responseType: 'blob', observe: 'response'});
    }

  listaZipponiRdo(): Observable<any> {
    return this.http.get(environment.apiUrl + `template/listaZipponiRdo`);
  }

  generaZipponeRdo(idRdo:number): Observable<any> {
    return this.http.get(environment.apiUrl + `template/generaZipponeRdo/${idRdo}?lingua=${environment.lang}`);
  }

  downloadZipponeRdo(nomeZippone:string): Observable<any> {
    return this.http.get(environment.apiUrl + `template/downloadZipponiRdo?nomeZippone=${nomeZippone}`, {responseType: 'blob'});
  }

}