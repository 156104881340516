import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ordine, OrdineItems } from '../_model/ordine';

@Injectable({
  providedIn: 'root'
})
export class OrdiniService {

  constructor(private http : HttpClient) { }

  /**
   * Ottieni la lista di ordini filtrati per fornitore
   * @param idFornitore id del fornitore
   */
  list(idFornitore?: number): Observable<Ordine[]>{
    return this.http.get<Ordine[]>(`${environment.apiUrl}ordine/list?idFornitore=${idFornitore}`);
  }

  /**
   * Crea o aggiorna un nuovo ordine
   * @param query Ordine
   */
  upsert(query: Ordine): Observable<Ordine>{
    return this.http.post<Ordine>(`${environment.apiUrl}ordine/createUpdate`, query);
  }


    /**
   * Ottieni un ordine
   * @param idRdo Id di un rdo
   */
     get(idRdo: number, idRdoFornitore: number): Observable<Ordine>{
      return this.http.get<Ordine>(`${environment.apiUrl}ordine/read?idRdo=${idRdo}&idRdoFornitore=${idRdoFornitore}`);
    } 
  
    
    /**
   * Ottieni un ordine
   * @param body Id di un rdo
   */
     updateList(body:OrdineItems[]): Observable<Ordine>{
      return this.http.post<Ordine>(`${environment.apiUrl}ordine/createUpdateListaItem`, body);
    } 


      /**
   * Ottieni la lista di items filtrati per ordine
   * @param idFornitore id del fornitore
   */
  listitems(idOrdine?: number, idRdoFornitore?: number): Observable<any[]>{
    return this.http.get<Ordine[]>(`${environment.apiUrl}ordine/listaItems?idOrdine=${idOrdine}&idRdoFornitore=${idRdoFornitore}`);
  }
}
