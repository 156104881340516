import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CambiaPasswordService {
    constructor(private http: HttpClient, private router: Router) {}

    cambiaPassword(userData){
        let errorText = "";
        let ctrlErrori= false;

        if(userData.vecchiaPassword == localStorage.getItem("pwd")){
            if(userData.nuovaPassword != userData.vecchiaPassword){
                if(userData.nuovaPassword == userData.confermaNuovaPassword){
                ctrlErrori = true;
                }else{
                    errorText = "La nuova password inserita non corrisponde con la verifica.";
                }
            }else{
                errorText = "La nuova password dev'essere diversa dall'attuale.";
            }
        }else{
            errorText = "La password attuale che hai inserito non corrisponde.";
        }

        if(ctrlErrori){
            this.password(userData).subscribe( res => {
                if(res === null){
                    localStorage.clear();
                    this.router.navigate(['/auth/logout']);
                }else{
                    errorText = "Si è verificato un errore. T'invitiamo a riprovare più tardi.";
                }
            });
        }

        return errorText;
    }

    password(userData){
        return this.http.put(environment.apiUrl + 'utente/changePassword?lingua=' + environment.lang, userData).pipe(map(res => res));
    }
}
