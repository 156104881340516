<div id="topLogin">
    <img src="assets/img/logoOG.png"/>
</div>

<div id="boxAutenticazione">
    <h5>Recupera password</h5>

    <form #recuperoPasswordForm="ngForm">
        <input [(ngModel)]="jsonIn.username" type="text" maxlength="200" class="form-control font-12" placeholder="Partita IVA" name="username" required autofocus>

        <div class="row">
            <div class="col-md-6">
                <button (click)="backToLogin()" class="btn px-5 py-2 btn-bg-empty">Annulla</button>
            </div>

            <div class="col-md-6">
                <button [disabled]="recuperoPasswordForm.invalid" (click)="recuperaPassword()" class="btn px-5 py-2 btn-bg-full">Invia</button>
            </div>
        </div>
    </form>
</div>
