import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastService } from '../_service/toast.service';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastService: ToastService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((res: any) => {
        if ( res && res.status < 400 && request.url.includes('api-ogp') && !request.url.includes('/html-base') && environment.showHttpLogs) {
          if (request.method === 'GET') {
            console.log (request.method + ' -', request.url.replace(environment.apiUrl, ''), '\n', JSON.parse(JSON.stringify(res.body)));
          } else {
            console.log (request.method + ' -', request.url.replace(environment.apiUrl, ''), '\nBody: ', JSON.parse(JSON.stringify(request.body)), '\n', JSON.parse(JSON.stringify(res.body)));
          }
        }
      }, error => {
        if (error.status === 401) {
          this.toastService.showDanger('Le credenziali non sono corrette oppure la sessione é scaduta.');
          this.router.navigate(['/auth/login']);
        } else {
          if (request.method === 'GET' && environment.showHttpLogs) {
            console.log (request.method + ' -', request.url.replace(environment.apiUrl, ''), '\n', error.message);
          } else if (environment.showHttpLogs){
            console.log (request.method + ' -', request.url.replace(environment.apiUrl, ''), '\nBody: ', request.body, '\n', error.message);
          }
        }
      })
    );
  }
}
