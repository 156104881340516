<div id="topLogin">
    <img src="assets/img/logoOG.png"/>
</div>

<div id="boxAutenticazione">
    <h5>Imposta una nuova password</h5>

    <form #loginForm="ngForm">
        <div class="form-group">
            <input type="password" class="form-control" id="nuovaPassword" placeholder="PASSWORD" name="nuovaPassword" [(ngModel)]="jsonin.nuovaPassword" required>
        </div>

        <div class="form-group">
            <input type="password" 
                   class="form-control"
                   [class.is-invalid]="(loginForm.touched && jsonin.nuovaPassword != jsonin.confermaNuovaPassword)" 
                   [class.is-valid]="(jsonin.nuovaPassword && jsonin.nuovaPassword === jsonin.confermaNuovaPassword)" 
                   id="confermaNuovaPassword" 
                   placeholder="CONFERMA PASSWORD" 
                   name="confermaNuovaPassword" 
                   [(ngModel)]="jsonin.confermaNuovaPassword" required>
            <div class="invalid-feedback">
                Le password devono corrispondere
            </div>
        </div>
        
        <div class="row">
            <div class="col">
                <button (click)="modificaPassword()" class="btn px-5 py-2 btn-bg-full" [disabled]="loginForm.invalid || jsonin.nuovaPassword != jsonin.confermaNuovaPassword">SALVA</button>
            </div>
        </div>
    </form>
</div>
