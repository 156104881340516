import { NgModule } from '@angular/core';
import { SafePipe } from './_pipe/safe.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { TranslateModule } from '@ngx-translate/core';
import { SessionService } from './_service/session.service';
import { EncodeUrlPipe } from './_pipe/encode-url.pipe copy';
import { MomentModule } from 'ngx-moment';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgbdSortableHeader } from './_directive/sortable.directive';
import { DominiService } from './_service/domini.service';
import { ConfigurazioniService } from './_service/configurazione.service';
import { SortByPipe } from './_pipe/sort-by.pipe';
import { FornitoriService } from './_service/fornitori.service';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ControlErrorContainerDirective } from './_directive/control-error-container.directive';
import { ControlErrorsDirective } from './_directive/control-errors.directive';
import { FormSubmitDirective } from './_directive/form-submit.directive';
import { RdoService } from './_service/rdo.service';
import { CambiaPasswordService } from 'src/app/shared/_service/cambioPassword.service';
import { ControlErrorComponent } from './modals/control-error/control-error.component';
import { PaginaNonTrovataComponent } from '../main/pagina-non-trovata/pagina-non-trovata.component';
import { HelpDeskComponent } from './_component/help-desk/help-desk.component';
import { OgModalComponent } from './modals/og-modal/og-modal.component';
import { RdoDettaglioDatiComponent } from './_component/rdo-dettaglio-dati/rdo-dettaglio-dati.component';
import { ProfiloUtenteComponent } from './_component/profilo-utente/profilo-utente.component';
import { ToastsContainerComponent } from './_component/toast-container.component';
import { ListaDocumentiRdoFornitoreComponent } from './_component/lista-documenti-rdo-fornitore/lista-documenti-rdo-fornitore.component';
import { TabellaFilesComponent } from './_component/lista-documenti-rdo-fornitore/tabella-files/tabella-files.component';
import { QuesitoComponent } from './_component/quesito/quesito.component';
import { OgAccordionComponent } from './_component/og-accordion/og-accordion.component';
import { NoDataComponent } from './_component/no-data/no-data.component';
import { OgUploadFileComponent } from './_component/og-upload-file/og-upload-file.component';
import { OgMultipleUploadFileComponent } from './_component/og-multiple-upload-file/og-multiple-upload-file.component';
import { CambiaEmailService } from './_service/cambioEmail.service';
import { OgSaveButtonComponent } from './_component/og-save-button/og-save-button.component';
import { SubmitGroupButtonsComponent } from './_component/submit-group-buttons/submit-group-buttons.component';
import { UploadDocumentComponent } from './modals/upload-document/upload-document.component';
import { StepQuesitiComponent } from './_component/step-quesiti/step-quesiti.component';
import { ListaQuesitiComponent } from './_component/step-quesiti/lista-quesiti/lista-quesiti.component';
import { QuesitoEditComponent } from './_component/step-quesiti/quesito-edit/quesito-edit.component';
import { QuesitoElementComponent } from './_component/step-quesiti/quesito-element/quesito-element.component';
import { EditIntegrazioneModalComponent } from './_component/lista-documenti-rdo-fornitore/edit-integrazione-modal/edit-integrazione-modal.component';
import { ViewIntegrazioneModalComponent } from './_component/lista-documenti-rdo-fornitore/view-integrazione-modal/view-integrazione-modal.component';
import { GestoreIntegrazioneComponent } from './_component/lista-documenti-rdo-fornitore/gestore-integrazione/gestore-integrazione.component';
import { SendEmailComponent } from './_component/send-email/send-email.component';
import { TabRichiestaIntegrazioneComponent } from './_component/tab-richiesta-integrazione/tab-richiesta-integrazione.component';
import { RteModalComponent } from './modals/rte-modal/rte-modal.component';
import { DettaglioOffertaRdoFornitoreComponent } from './_component/dettaglio-offerta-rdo-fornitore/dettaglio-offerta-rdo-fornitore.component';
import { RdoFornitoreService } from './_service/rdo-fornitore.service';
import { OffertaEconomicaService } from './_service/offerta-economica.service';
import { TemplateService } from './_service/template.service';
import { OrdiniService } from './_service/ordine.service';
import { DettaglioVisioneRdoComponent } from './_component/dettaglio-visione-rdo/dettaglio-visione-rdo.component';
import { ConsegnaCollaudoService } from './_service/consegnaCollaudo.service';
import { DettaglioSopralluogoRdoComponent } from './_component/dettaglio-sopralluogo-rdo/dettaglio-sopralluogo-rdo.component';
import { LoggerService } from './_service/logger.service';
import { ImpostazioniSidebarComponent } from './_component/impostazioni-sidebar/impostazioni-sidebar.component';
import { RouterModule } from '@angular/router';
import { DuplicaRdoModalComponent } from './modals/duplica-rdo-modal/duplica-rdo-modal.component';
import { VendorRatingService } from './_service/vendorRating.service';
import { VendorRatingComponent } from './_component/vendor-rating/vendor-rating.component';
import { VendorRatingGeneralComponent } from './_component/vendor-rating-general/vendor-rating-general.component';
import { OgAccordionFaqComponent } from './_component/og-accordion-faq/og-accordion-faq.component';
import { FaqService } from './_service/faq.service';
import { QuesitoModerazioneComponent } from './_component/quesito-moderazione/quesito-moderazione.component';
import { FaqComponent } from './_component/faq/faq.component';
import { GestioneFattureOgComponent } from './_component/gestione-fatture-og/gestione-fatture-og.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { TagModalComponent } from './_component/tag-modal/tag-modal.component';
import { TagService } from './_service/tag.service';
import { TagModificaModalComponent } from './_component/tag-modifica-modal/tag-modifica-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    SafePipe,
    EncodeUrlPipe,
    SortByPipe,
    NgbdSortableHeader,
    ControlErrorContainerDirective,
    ControlErrorsDirective,
    ControlErrorComponent,
    FormSubmitDirective,
    ConfirmModalComponent,
    PaginaNonTrovataComponent,
    HelpDeskComponent,
    OgModalComponent,
    RdoDettaglioDatiComponent,
    ProfiloUtenteComponent,
    ToastsContainerComponent,
    QuesitoComponent,
    NoDataComponent,
    ListaDocumentiRdoFornitoreComponent,
    TabellaFilesComponent,
    QuesitoComponent,
    OgAccordionComponent,
    OgUploadFileComponent,
    OgMultipleUploadFileComponent,
    OgSaveButtonComponent,
    SubmitGroupButtonsComponent,
    UploadDocumentComponent,
    StepQuesitiComponent,
    ListaQuesitiComponent,
    QuesitoEditComponent,
    QuesitoElementComponent,
    EditIntegrazioneModalComponent,
    ViewIntegrazioneModalComponent,
    GestoreIntegrazioneComponent,
    SendEmailComponent,
    TabRichiestaIntegrazioneComponent,
    RteModalComponent,
    DettaglioOffertaRdoFornitoreComponent,
    DettaglioVisioneRdoComponent,
    DettaglioSopralluogoRdoComponent,
    ImpostazioniSidebarComponent,
    DuplicaRdoModalComponent,
    VendorRatingComponent,
    VendorRatingGeneralComponent,
    OgAccordionFaqComponent,
    QuesitoModerazioneComponent,
    FaqComponent,
    GestioneFattureOgComponent,
    TagModalComponent,
    TagModificaModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule.forChild(),
    BlockUIModule,
    MomentModule,
    NgSelectModule,
    UiSwitchModule,
    CKEditorModule,
    RouterModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    SafePipe,
    SortByPipe,
    EncodeUrlPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BlockUIModule,
    TranslateModule,
    MomentModule,
    NgSelectModule,
    UiSwitchModule,
    CKEditorModule,
    ControlErrorContainerDirective,
    ControlErrorsDirective,
    NgbdSortableHeader,
    FormSubmitDirective,
    ControlErrorComponent,
    PaginaNonTrovataComponent,
    HelpDeskComponent,
    OgModalComponent,
    RdoDettaglioDatiComponent,
    ProfiloUtenteComponent,
    ToastsContainerComponent,
    QuesitoComponent,
    NoDataComponent,
    ListaDocumentiRdoFornitoreComponent,
    QuesitoComponent,
    OgAccordionComponent,
    OgUploadFileComponent,
    OgMultipleUploadFileComponent,
    OgSaveButtonComponent,
    SubmitGroupButtonsComponent,
    StepQuesitiComponent,
    ListaQuesitiComponent,
    QuesitoEditComponent,
    QuesitoElementComponent,
    SendEmailComponent,
    TabRichiestaIntegrazioneComponent,
    DettaglioOffertaRdoFornitoreComponent,
    TabellaFilesComponent,
    DettaglioVisioneRdoComponent,
    GestoreIntegrazioneComponent,
    DettaglioSopralluogoRdoComponent,
    ImpostazioniSidebarComponent,
    DuplicaRdoModalComponent,
    VendorRatingComponent,
    VendorRatingGeneralComponent,
    OgAccordionFaqComponent,
    QuesitoModerazioneComponent,
    FaqComponent,
    GestioneFattureOgComponent,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    TagModalComponent,
    TagModificaModalComponent,
    NgMultiSelectDropDownModule
  ],
  providers: [SessionService, DominiService, ConfigurazioniService, FornitoriService, RdoService, CambiaPasswordService, CambiaEmailService, RdoFornitoreService, OffertaEconomicaService, TemplateService, OrdiniService, ConsegnaCollaudoService, LoggerService, VendorRatingService, FaqService, TagService],
  entryComponents: [ControlErrorComponent, OgModalComponent, EditIntegrazioneModalComponent, ViewIntegrazioneModalComponent, DuplicaRdoModalComponent]
})
export class SharedModule { }

