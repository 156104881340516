import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/_service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { OpenTelemetryService } from './auth/_service/open-telemetry/open-telemetry.service';
import { LogService } from './auth/_service/open-telemetry/log.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
    private openTelemetryService: OpenTelemetryService,
    private logService: LogService,
  ) {
      translate.setDefaultLang(environment.lang);
      translate.use(environment.lang);

      // Inizializzo il servizio OpenTelemetry
      const openTelemetryOptions = {
        apiUrl: environment.openTelemetryApiUrl,
        serviceName: environment.openTelemetryServiceName,
        attributes: environment.openTelemetryAttributes,
        trace: true,
        log: true,
      };
      openTelemetryService.inizializza(openTelemetryOptions);
      openTelemetryService.idUtenteTrace = (JSON.parse(localStorage.getItem('user') || '{}').idUtente + '') || '';
  
      // Inizializzo il servizio di log
      logService.inizializza();
      logService.abilitaLogOpenTelemetry('warn');
    }

  ngOnInit() {

    if (this.auth.isLoggedIn) {
      // Carico in sessione i prodotti e il carrello
    }

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

  }

  ngOnDestroy() {
    // do nothing
  }
}
