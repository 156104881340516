import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  public canActivate() {
    if (localStorage.token && localStorage.user) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
