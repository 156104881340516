import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Utente } from 'src/app/shared/_model/common';
import { Injectable } from "@angular/core";


@Injectable()
export class ConfigurazioniService {
  constructor(private http: HttpClient) {}
   
  private httpOptions = {
     headers: new HttpHeaders({
     'Content-Type':  'application/json',
     'Authorization': localStorage.getItem('token')
     })
  };

  
  /*****************************
  *********** UNTENTI ********** 
  *****************************/
  listaUtenti(jsonIn): Observable<any> {
    const params = new HttpParams()
    .set("lingua", environment.lang)
    .set('codiceProfilo', jsonIn);

    return this.http.get(environment.apiUrl + "utente/list", { params }).pipe(map((res: any) => {return res;}));
  }

  nuovoUtente(jsonIn: Utente): Observable<any> {
    return this.http.post(environment.apiUrl + 'utente/create?lingua=' + environment.lang, jsonIn).pipe(map((response: any) =>  response.result));
  }

  updateUtente(jsonIn: Utente): Observable<any> {
    return this.http.put(environment.apiUrl + 'utente/update?lingua=' + environment.lang, jsonIn).pipe(map((response: any) =>  response));
  }

  dettaglioUtente(idUtente: number): Observable<Utente> {
    return this.http.get<Utente>(environment.apiUrl + 'utente/read/' + idUtente + '?lingua=' + environment.lang).pipe(
      tap(res => localStorage.setItem('userinfo', JSON.stringify(res)))
    );
  }

  deleteUtente(jsonIn:number) {
    return this.http.delete(environment.apiUrl + 'utente/delete/' + jsonIn + '?lingua=' + environment.lang, {responseType: 'text'}).pipe();
  }
  
  deactivateUtente(jsonIn) {
    return this.http.delete(environment.apiUrl + 'utente/deactivate/' + jsonIn + '?lingua=' + environment.lang, {responseType: 'json'}).pipe();
  }
}
