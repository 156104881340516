import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from '../auth/_service/auth.service';
import { sharedConstants } from '../shared/const';
import { MessageService } from "../shared/_service/message.service";
import { SessionService } from '../shared/_service/session.service';

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
})
export class MainComponent implements OnInit {

  showPagine = false;
  burgerMenu: boolean;
  isAdmin: boolean;
  isFornitore: boolean;
  isOspedale: boolean;
  idFrn: number;
  isArredi = false;

  constructor(
    private session: SessionService, 
    private authService: AuthService, 
    private router: Router,
    private messageService: MessageService) {
  }

  ngOnInit() {
   // const user = this.session.getLocal('user');
    this.idFrn = this.session.getLocal('idFrn');
    switch (this.authService.userType) {
      case 'FRN': this.isFornitore = true; this.isOspedale = false; this.isAdmin = false;
        break;
      case 'OSP': this.isFornitore = false; this.isOspedale = true; this.isAdmin = false;
        break;
      case 'ADM': this.isFornitore = false; this.isOspedale = false; this.isAdmin = true;
        break;
      default: this.isFornitore = false; this.isOspedale = false; this.isAdmin = false;
        break;
    }
    this.burgerMenu = false;

    this.isArredi = localStorage.codiceArea === sharedConstants.codiceAreaArredamento;
    const body = document.getElementsByTagName('body')[0];
    if (this.isArredi) {
      body.classList.add('themeArredamento');
      localStorage.setItem("codiceArea", sharedConstants.codiceAreaArredamento);
    } else {
      body.classList.remove('themeArredamento');
      localStorage.setItem("codiceArea", sharedConstants.codiceAreaAttrezzature);
    }

  }

  onChangeTipoRdo(evento) {
    const body = document.getElementsByTagName('body')[0];

    if (evento) {
      body.classList.add('themeArredamento');
      localStorage.setItem("codiceArea", sharedConstants.codiceAreaArredamento);
    } else {
      body.classList.remove('themeArredamento');
      localStorage.setItem("codiceArea", sharedConstants.codiceAreaAttrezzature);
    }

    if (this.isAdmin){
    this.router.navigate(['/main/home'])
    } else if (this.isOspedale) {
    this.router.navigate(['/ospedale/home'])

    }

    this.messageService.messages.emit("CAMBIO CODICE AREA");
  }
}
