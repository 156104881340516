<nav class="navbar fixed-top bg-color-white flex-md-nowrap" >
  <!-- <button type="button" class="btn btn-link d-lg-none" (click)="burgerMenu = !burgerMenu"><i class="fas fa-bars text-azure"></i></button> -->
  <a class="navbar-brand ml-2 d-none d-lg-block" [routerLink]="['']">
    <img src="assets/img/logoOGorizzontale.png">
  </a>
  <ul class="navbar-nav px-3 d-flex flex-row align-items-center">
    <li class="mr-3" id="switchCambioTipoRdo" *ngIf="!isFornitore">
      <ui-switch  size="large"
                  checkedLabel="ARREDI" uncheckedLabel="ATTREZZATURE"
                  defaultBgColor="#1A2B57" 
                  color="#36571a" checkedTextColor="#fff" uncheckedTextColor="#fff"
                  [ngModel]="isArredi"
                  (change)="onChangeTipoRdo($event)">
      </ui-switch>
    </li>
    <li class="mr-3" *ngIf="!isOspedale">
      <a class="btn btn-link" 
         [routerLink]="['/'+(isAdmin? 'main' : isFornitore? 'fornitori': isOspedale? 'ospedale' : '')+'/help-desk']" 
         placement="bottom" 
         ngbTooltip="Assistenza">
        <i class="font-20 fas fa-headset"></i>
      </a>
    </li>
    <!-- TODO abilitare in base al profilo -->
    <!-- usare nfIF oppure diabled -->
    <!-- <li class="mr-3">
      <button class="btn btn-link" disabled>
        <i class="font-20 fas fa-bell"></i>
      </button>
    </li> -->
    <li class="mr-3">
      <!-- <button class="btn btn-link removeAfter" [routerLink]="['/main/profilo']" *ngIf="isAdmin"><i class="font-20 fas fa-user"></i></button>
      <button class="btn btn-link removeAfter" [routerLink]="['/fornitori/profilo']" *ngIf="isFornitore"><i class="font-20 fas fa-user"></i></button>
      <button class="btn btn-link removeAfter" [routerLink]="['/ospedale/profilo']" *ngIf="isOspedale"><i class="font-20 fas fa-user"></i></button> -->
      <a class="btn btn-link removeAfter" 
      [routerLink]="['/'+(isAdmin? 'main' : isFornitore? 'fornitori': isOspedale? 'ospedale' : '')+'/profilo']" 
         placement="bottom" 
         ngbTooltip="Profilo">
        <i class="font-20 fas fa-user"></i>
      </a>

    </li>
    <li>
      <button class="btn btn-bg-empty" [routerLink]="['/auth/logout']" type="button">LOGOUT</button>
    </li>
  </ul>
</nav>

<div class="container-fluid" id="main-container">
  <div class="row containerFluidWrapper">
    <nav class="p-0 col-1 col-xl-2 sidebar position-fixed d-none d-md-block" style="overflow-y: auto">
      <div class="sidebar-sticky h-100">
        <app-sidebar-og *ngIf="isAdmin"></app-sidebar-og>
        <app-sidebar-fornitori *ngIf="isFornitore && idFrn"></app-sidebar-fornitori>
        <app-sidebar-ospedale *ngIf="isOspedale"></app-sidebar-ospedale>
      </div>
    </nav>

    <div class="col-xl-10 col-11 ml-sm-auto px-5">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>