import { Component } from '@angular/core';
import { AuthService } from '../_service/auth.service';
import { Router } from '@angular/router';
import { FornitoriService } from 'src/app/shared/_service/fornitori.service';
import { ConfigurazioniService } from 'src/app/shared/_service/configurazione.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class AuthLoginComponent {

  constructor(
    private router: Router,
    private auth: AuthService,
    private fornitori: FornitoriService,
    private serviceConf: ConfigurazioniService
  ) { }

  user: any = {};

  /** da implementare le parti commentate quando ci saranno i servizi per gestirli
   *  permette di verificare se l'utente che si sta loggando è al primo accesso o meno
   */
  login() {
    this.auth.login(this.user).subscribe(lRes => {
      if (lRes) {
        this.serviceConf.dettaglioUtente(lRes.idUtente).subscribe(duRes => {
          if (this.auth.isAdmin) {
            this.router.navigate(['/main/home']);

          } else if (this.auth.isOspedale) {
            this.router.navigate(['/ospedale/home']);

          } else {
            if (this.auth.isFornitore) {
              localStorage.setItem('idFrn', duRes.fornitoreId + '');
              localStorage.setItem('frn', JSON.stringify(duRes)); // La chiamata "dettaglioUtente" (il read) salva in locale ('userinfo') le info dello user.
              this.fornitori.dettaglioFornitore(duRes.fornitoreId).subscribe(resF => {
                this.auth.checkerMissingInfoFornitore(resF);
              });
            }
          }
        });
      }
    });
  }

  openRecuperaPassword() {
    this.router.navigate(['/auth/recupera-password']);
  }

  openRegistrazioneFornitore() {
    localStorage.clear();
    
    this.router.navigate(['/auth/registrazione-fornitore']);
  }
}
