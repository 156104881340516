import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Fornitore } from 'src/app/shared/_model/fornitore';
import { FileFormat } from 'src/app/shared/_model/common';
import * as moment from 'moment';
import { sharedConstants } from 'src/app/shared/const';

@Component({
  selector: 'app-legale-rappresentante',
  templateUrl: './legale-rappresentante.component.html',
  styles: []
})
export class LegaleRappresentanteComponent implements OnInit {

  @Output() previousStep = new EventEmitter<void>();
  @Output() lastStep = new EventEmitter<any>();

  legaleRappresentante: Fornitore = new Fornitore();
  scadenzaDocumentoFirmatario: string;
  dataNascitaFirmatario: string;
  ruoli = [
    {name: "Legale rappresentante"},
    {name: "Procuratore"},
  ];

  constructor() {
    // do nothing
  }

  ngOnInit() {
    if (localStorage.getItem('legaleRappresentante')) {
      this.legaleRappresentante = JSON.parse(localStorage.getItem('legaleRappresentante'));
    } else {
      this.legaleRappresentante.identitaFirmatarioOfferta = {
        mimeType: '',
        stringBase64:'',
        nomeFile:''
      }
    }
  }

  dataScadenzaChanged() {
    this.legaleRappresentante.scadenzaDocumentoFirmatario = moment.utc(this.scadenzaDocumentoFirmatario, sharedConstants.dataPattern).toISOString() + "";
  }

  dataNascitaChanged() {
    this.legaleRappresentante.dataNascita = moment(this.dataNascitaFirmatario, sharedConstants.dataPattern).format(sharedConstants.dataPattern2) + "";
  }
    
  fileChange(files) {
    if (files.length > 0) {
      // tslint:disable-next-line: no-use-before-declare
      const payload = new FileFormat(files[0]);
      // Base64 Conversion
      const readerBase64 = new FileReader();
      readerBase64.onload = async (event: any) => {
        const binaryString = event.target.result;
        const base64 = btoa(binaryString);
        payload.stringBase64 = base64;
        this.legaleRappresentante.identitaFirmatarioOfferta.mimeType = payload.mimeType;
        this.legaleRappresentante.identitaFirmatarioOfferta.stringBase64 = payload.stringBase64;
        this.legaleRappresentante.identitaFirmatarioOfferta.nomeFile = payload.nomeFile;
      };
      readerBase64.readAsBinaryString(files[0]);
    }
  }

  salva() {
    localStorage.setItem('legaleRappresentante', JSON.stringify(this.legaleRappresentante));

    this.lastStep.emit();
  }

  indietro() {
    localStorage.setItem('legaleRappresentante', JSON.stringify(this.legaleRappresentante));

    this.previousStep.emit();
  }
}
