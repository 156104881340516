import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sharedConstants } from 'src/app/shared/const';

@Component({
  selector: 'app-lista-contatti',
  templateUrl: './lista-contatti.component.html'
})
export class ListaContattiComponent implements OnInit {

  @Input() contattiFornitore: any;
  @Output() aggiungiContatto = new EventEmitter<any>();

  ruoli: any = [
    {codice: "AMM", descrizione: "Ufficio amministrativo/finanziario"},
    {codice: "COM", descrizione: "Referente commerciale"},
    {codice: "GAR", descrizione: "Ufficio gare / Referente RDO"},
    {codice: "ECO", descrizione: "Ufficio gestione ordini"},
  ];

  totaleRisultati = 0;
  nPagina = 1;
  risultatiPerPagina: number = sharedConstants.numPerPaginaDefault;

  constructor() {
    // do nothing
  }

  ngOnInit() {

    // tengo traccia del totale dei contatti per la tabella
    this.totaleRisultati = this.contattiFornitore.length;

    // menata per i ruoli
    this.contattiFornitore.forEach((contatto: any) => {
      contatto.descrizioneRuolo = this.ruoli.find((x: any) => x.codice === contatto.ruolo).descrizione;
    });
  }

  nuovoContatto() {
    this.aggiungiContatto.emit(null);
  }

  modificaContatto(contatto: any) {
    this.aggiungiContatto.emit(contatto);
  }

  cancellaContatto(contattoDaCancellare: any) {
    const filtered = [];

    // tolgo dalla lista il contatto
    this.contattiFornitore.forEach(contatto => {
      if (contatto !== contattoDaCancellare) {
        filtered.push(contatto);
      }
    });

    this.contattiFornitore = filtered;

    // aggiorno il localStorage
    localStorage.setItem('contattiFornitore', JSON.stringify(this.contattiFornitore));
  }
}
