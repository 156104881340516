import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() {
    // do nothing
  }

  log(message) {
    if (environment.showHttpLogs) {
      console.log(message);
    }
  }
}
