export enum CodiceTemplate {
  //TEMPLATE RDO ARREDI
  ACCAR = 'ACCAR', //T EMPLATE RDO ACCESSORI ARREDI
  ECOAR = 'ECOAR', //TEMPLATE RDO ECONOMIA ARREDI
  ATNAR = 'ATNAR', //TEMPLATE RDO ALTA TECNOLOGIA ARREDI
  ATLAR = 'ATLAR', //TEMPLATE RDO ALTA TECNOLOGIA CON LAVORI ARREDI


  //TEMPLATE RDO ATTREZZATURE
  ACC = 'ACC', //TEMPLATE RDO ACCESSORI ATTREZZATURE
  ECO = 'ECO', //TEMPLATE RDO ECONOMIA ATTREZZATURE
  ATN = 'ATN', //TEMPLATE RDO ALTA TECNOLOGIA ATTREZZATURE
  ATL = 'ATL', //TEMPLATE RDO ALTA TECNOLOGIA CON LAVORI ATTREZZATURE


  //TEMPLATE OFFERTA ECONOMICA
  DOF = 'DOF', // DICHIARAZIONE OFFERTA
  DLA = 'DLA', // DICHIARAZIONE LAVORI
  DRI = 'DRI', // DICHIARAZIONE RITIRO
  PRO = 'PRO', // PRODOTTI
  CON = 'CON', // CONSUMABILI
  OPZ = 'OPZ', // OPZIONI


  //TEMPLATE OFFERTA TECNICA
  DDT = 'DDT', // DICHIARAZIONI DOCUMENTI TECNICI
  DEP = 'DEP', // DESCRIZIONE PARTICOLAREGGIATA 
  DPP = 'DPP', // PUNTO PUNTO
  DPPAR = 'DPPAR', // PUNTO PUNTO ARREDI
  DUN = 'DUN', // DICHIARAZIONE UNICA
  NLV = 'NLV', // NOMINATIVI LAVORI


  //TEMPLATE ORDINE
  OPFAT = 'OPFAT', // FORNITORE PROVVISORIO ALTA TECNOLOGIA CON/SENZA LAVORI
  OPFAE = 'OPFAE', // FORNITORE PROVVISORIO ACCESSORI ED ECONOMIE
  OPDFAT = 'OPDFAT', // FORNITORE DEFINITIVO ALTA TECNOLOGIA CON/SENZA LAVORI
  OPDFAE = 'OPDFAE', // FORNITORE DEFINITIVO ACCESSORI ED ECONMIE
  ODDF = 'ODDF', // FORNITORE DEFINITIVO DIRETTO ATTREZZATURE
  ODDFAR = 'ODDFAR', // FORNITORE DEFINITIVO DIRETTO ARREDI
  OPULSS = 'OPULSS', // ULSS PROVVISORIO
  PDFORDINE = 'PDFORDINE', // GENERAZIONE PDF ORDINE
  OPFARR = 'OPFARR', // ORDINE PROVVISIORIO FORNITURE ARREDI
	OPDFARR = 'OPDFARR', // ORDINE PROVVISORIO DEFINITIVO FORNITURE ARREDI


  //TEMPLATE CONSEGNA E COLLAUDO 
  DVDC = 'DVDC', // VERBALE DI CONSEGNA
  DFCC = 'DFCC', // FORNITURA CONSEGNA COLLAUDO
  DRDC = 'DRDC', // REPORT COLLAUDO
  DVCO = 'DVCO', // VERBALE DI COLLAUDO
  PDFCONSEGNACOLLAUDO = 'PDFCONSEGNACOLLAUDO', // GENERAZIONE PDF CONSEGNA E COLLAUDO


  //TEMPLATE ARTICOLO 8
  A80 = 'A80', // ARTICOLO 80


  //TEMPLATE CONSEGNA OFFERTA
  COF = 'COF', // CONSEGNA OFFERTA FORNTIORE
  PCO = 'PCO', // PROTOCOLLO CONSEGNA OFFERTA ATTREZZATURE
  PCOAR = 'PCOAR', // PROTOCOLLO CONSEGNA OFFERTA ARREDI


  //TEMPLATE E-MAIL
  EML = 'EML', // FORNITORE INVITO RDO
  INT = 'INT', // FORNITORE INTEGRAZIONI
  UOD = 'UOD', // ULSS ORDINE DEFINITIVO



  //ALTRI CODICI TEMPLATE
  SUB = 'SUB', // DOCUMENTO SUBAPPALTO
  PDL = 'PDL', // PROGETTO DEFINITIVO LAVORI
  DCP = 'DCP', // DISSOCIAZIONE CONDOTTA PERSONALE
  DID = 'DID', // DOCUMENTO IDENTITA
  PGS = 'PGS', // PROCURA GENERALE SPECIALE
  OEP = 'OEP', // OFFERTA ECONOMICA CON PREZZI
  OES = 'OES', // OFFERTA ECONOMICA SENZA PREZZI
  OTU = 'OTU', // OFFERTA TECNICA
  OAM = 'OAM', // OFFERTA AMMINISTRATIVA
  VIC = 'VIC', // VISURA CAMERALE
  DOA = 'DOA', // DOCUMENTI AGGIUNTIVI OFFERTA AMMINISTRATIVA
  COG = 'COG', // DOCUMENTI CONSEGNA OFFERTA OG
  VIS = 'VIS', // DOCUMENO VISIONE
  VB3 = 'VB3', // DOCUMENTO B3 PER LE VISIONI
  DVR = 'DVR', // DOCUMENTO COVID PER LA VISIONE
  POL = 'POL', // POLIZZE
  DUV = 'DUV',
  VAM = 'VAM', // DOCUMENTAZIONE ANTIMAFIA
  GMR = 'GMR',
  CFO = 'CFO', // CONDIZIONI GENERALI FORNITURA
  EVS = 'EVS',
  PEO = 'PEO', // DOCUMENTAZIONE PROGETTO ESECUTIVO ORDINE
  SAO = 'SAO', // DOCUMENTAZIONE SUBAPPALTO ORDINE
  OPC = 'OPC', // DOCUMENTAZIONE PROPOSTA CONSEGNA
  PAO = 'PAO', // DOCUMENTAZIONE POLIZZE ASSICURATIVE ORDINE
  OCF = 'OCF', // DOCUMENTAZIONE CONDIZIONI GENERALI DI FORNITURA ORDINE
  ODU = 'ODU', // DOCUMENTAZIONE DURC
  CCD = 'CCD', // DICHIARAZIONE CONTO CORENTE DEDICATO ORDINE
  ATIO = 'ATIO', // ATTO DI COSTITUZIONE DELL'ATI
  GMRO = 'GMRO', // GMR ORDINE
  DUVO = 'DUVO', // DUVRI ORDINE
  CDO = 'CDO', // CAUZIONE DEFINITIVA ORDINE
  SSO = 'SSO', // SCHEDE DI SICUREZZA ORDINE
  ADO = 'ADO', // AUTODICHIARAZIONI ORDINE
  CPO = 'CPO', // CONSISTENZA DEL PERSONALE ORDINE
  CRC = 'CRC', // CERTIFICATO REGOLARITA CONTRIBUTIVA ORDINE
  AMO = 'AMO', // ANTIMAFIA ORDINE
  CDF = 'CDF', // CONTRATTO DI FORNITURA
  CCO = 'CCO', // CERTFICATO CASELLARIO ORDINE
  ASA = 'ASA', // ANAGRAFE SANZIONI AMMINISTRATIVE
  CPP = 'CPP', // CARICHI PENALI PENDENTI
  MTM = 'MTM', // MANUALE TECNICO
  PLT = 'PLT', // PART LIST
  SEL = 'SEL', // SCHEMI ELETTRICI
  DSI = 'DSI', // DOCUMENTAZIONE SOFTWARE INSTALLATO
  DAEO = 'DAEO', // DOCUMENTAZIONE AGGIUNTIVA 
  ESO = 'ESO', // ESITO SOPRALLUOGO 
  AAV = 'AAV', // AUTORIZZAZIONE ALLE VISIONI
  DAUO = 'DAUO', // DOCUMENTAZIONE AGGIUNTIVA UPLOAD ORDINI INFO ULLS
  COPF = 'COPF', // DOCUMENTI ORDINE PROVVISORIO CONSEGNA FORNITORE
  CODF = 'CODF', // DOCUMENTI ORDINE DEFINITIVO CONSEGNA FORNITORE 
  DACO = 'DACO', // DOCUMENTAZIONE AGGIUNTIVA CONSEGNA AL FORNITORE
  COPU = 'COPU', // consegna offerta provvisoria ulss
  CODU = 'CODU', // consegna offerta definitiva ulss
  PCC = 'PCC', //DOCUMENTO RDO PROPOSTA CONSEGNA E COLLAUDO
  COFRDO = 'COFRDO' //PDF DOCUMENTO CONFERMA OFFERTA

}

export const sharedConstants = {
  precompilatoRappresentanteLegale: 'Il sottoscritto #nome nato a #cittaNascita il #dataNascita, residente in #cittaResidenza in #indirizzoResidenza, in qualità di #ruolo della società #societa con sede in #sedeSocieta in #indirizzo  con partita IVA n° #piva, numero di telefono #telefono',

  emailPattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  dataPattern: 'YYYY-MM-DD',
  dataPattern2: 'DD/MM/yyyy',
  dataPatternIsoString: 'T00:00:00Z',
  dataPatternIsoString2: 'T00:00:00.000Z',

  salvataggioErrore: "Si é verificato un errore nel salvataggio!",
  salvataggioAvvenuto: "Salvataggio avvenuto correttamente!",
  documentiCaricati: "Documenti caricati correttamente!",
  documentiCaricatiErrore: "Si é verificato un errore durante il caricamento dei documenti!",
  clonazioneAvvenuta: "Elemento clonato corretamente!",

  frecciaSu: "fas fa-caret-up",
  frecciaGiu: "fas fa-caret-down",

  notApplicable: "NOT APPLICABLE",

  numPerPaginaDefault: 50,

  rdo: "RDO",

  //area RDO
  codiceAreaAttrezzature: 'ATT',
  codiceAreaArredamento: 'ARR',

  //azioni bottoni
  prosegui: 'PROSEGUI',
  aggiorna: 'AGGIORNA',
  salva: 'SALVA',

  //tipologie RDO
  economie: 'ECO',
  altaTecnologiaConLavori: 'ATL',
  accessori: 'ACC',
  altaTecnologia: 'ATN',

  //profili utenze
  ospedale: "OSP",
  fornitore: "FRN",
  ospedalGrando: "ADM",

  //contatti fornitori
  ufficioGare: "GAR",

  //tmpl email invito RDO
  email: "EML",
  emailConfermaOfferta: "EMLCO",

  //doc RDO
  letteraAccompagnamento: 'LAC',
  perRdoUlss: 'PRU',
  condizioniGeneraliFornitura: 'CGF',
  documentiSopralluogo: "DOS",
  capitolatoPrestazionale: "CPR",

  //doc elementi
  depliant: 'DPL',
  depliantBrochureSchedaTecnica: 'DBS',
  destinazioneUso: 'DSU',
  dichiarazioneConformita: 'DIC',
  certificatoCE: 'CCE',
  dichiarazioneConformitaRohs: 'RHS',
  manualeUsoItaliano: 'MUI',
  protocolliManutenzionePreventiva: 'PMP',
  normativaAntincendio: 'ANT',

  //doc generici RDO
  documentiGmrs: 'GMR',
  delegaSopralluogo: 'DSO',
  disciplinareFornitori: 'DFO',
  protocolloLegalita: 'PLE',
  codiceEtico: 'CET',
  condizioniFornitura: 'CFO',
  privacy: 'PRI',
  verbaleCollaudo: 'VCO',
  fidejussioneBancaria: 'FID',
  verificheAntimafia: 'VAM',
  richiestaSubappalto: 'SUB',
  polizze: 'POL',
  documentoFornituraArredi: 'DFA',

  ckeditorUrl: '',

  tipiFileToUpload: 'application/pdf,.doc,.docx,application/msword,.msg,application/octet-stream,application/vnd.ms-outlook,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.bz,.bz2,.7z,.zip,.rar,.pdf,.p7m',
  dashboardHomeValue: 'Benvenuto nel portale <b class=\'color-cprimary\'>Richiesta Di Offerta</b> di Ospedal Grando.',
  dashboardHomeValueOspedale: 'Benvenuto nel portale di Procurement di Ospedal Grando.',


  mailto: 'mailto:?bcc=',

  extensionPdf: 'pdf',
  extensionZip: 'zip',

  indicazioniStandard: 'Si chiede di indicare:<ul><li>Modalità, data e ora di consegna dell’apparecchio</li><li>Modalità, data e ora indicativa di ritiro dell’apparecchio</li><li>Nominativo e Recapito del Product Specialist</li></ul>',

  idFornitoreOspedalGrando: 278,

  margineSuCosto: 1125000.00/6375000.00
};

export class CkeConfigurationPlaceholders {
  configuration;
  constructor(height: number) {
    this.configuration = {
      removePlugins: ['autoplaceholder'],
      height: height,
      extraPlugins: ['base64image'],
      toolbar: [
        ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
        ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
        ['Link', 'Unlink', 'Anchor', '-', 'Table', 'HorizontalRule', 'SpecialChar', 'base64image'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat', '-', 'TextColor', 'BGColor'],
        '/',
        ['Styles', 'Format', 'FontSize'],
        ['CreatePlaceholder', 'ExportPdf']
      ]
    };
  }
}
export class CkeConfigurationFull {
  configuration;
  constructor(height: number) {
    this.configuration = {
      removePlugins: ['exportpdf', 'autoplaceholder'],
      height: height,
      extraPlugins: ['base64image'],
      toolbar: [
        ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
        ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
        ['Link', 'Unlink', 'Anchor', '-', 'Table', 'HorizontalRule', 'SpecialChar', 'base64image'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat', '-', 'TextColor', 'BGColor'],
        '/',
        ['Styles', 'Format', 'FontSize'],
      ]
    };
  }
}
export class CkeConfiguration {
  configuration;
  constructor(height: number) {
    this.configuration = {
      removePlugins: ['exportpdf', 'autoplaceholder'],
      height,
      extraPlugins: ['base64image', 'resize'],
      toolbar: [
        ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
        ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
        ['Link', 'Unlink', '-', 'Table', 'HorizontalRule', 'SpecialChar', 'base64image'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat', '-', 'TextColor', 'BGColor'],
      ]
    };
  }

}


export enum ListaVisioni {
  opzioni = 'OPZ',
  elementi = 'ELE'
}



export class SetPlaceholders {
  constructor() {
    // do nothing
  }

  static setTemplatePlaceholders(listaPlaceholders) {
    const arrayResult = []
    if (listaPlaceholders && listaPlaceholders.length > 0) {
      listaPlaceholders.forEach(placeholder => {
        let array = [placeholder.descrizione, '${' + placeholder.placeholder + '}'];
        arrayResult.push(array);
        array = [];
      });
    }

    return arrayResult;
  }

  static creaComponentePlaceholders(listaPlaceholders, ckeditorInstance) {
    ckeditorInstance.on('dialogDefinition', function (event) {
      if ('placeholder' == event.data.name) {
        const input = event.data.definition.getContents('info').get('name');
        input.type = 'select';
        input.className = 'form-control';
        input.label = 'Seleziona un campo';
        input.items = listaPlaceholders;
      }
    });
  }
}

export class SetImage {
  constructor() {
    // do nothing
  }

  static setImage(ckeditorInstance) {
    ckeditorInstance.on('dialogDefinition', function (event) {
      if ('base64imageDialog' == event.data.name) {
        event.data.definition.getContents('tab-properties');
      }
    });
  }
}

export class Domanda {
  constructor() {
    // do nothing
  }

  domanda: string;
  id: number;
  haRisposte: boolean;
  visibileOspedale: boolean;
  attiva: boolean;
  
  
}

  //     '/',
  //     [ 'CreatePlaceholder' ]