import { EventEmitter, Injectable } from "@angular/core";
import { AuthService } from "src/app/auth/_service/auth.service";

@Injectable()
export class SessionService {

  constructor(
    private auth: AuthService) {}

  carrelloChange = new EventEmitter<any>();

  getStorage(key) {
    const val = sessionStorage.getItem(key);
    return val ? JSON.parse(val) : null;
  }

  setStorage(key, obj) {
    const object = obj ? JSON.stringify(obj) : null;
    sessionStorage.setItem(key, object);
  }
  setLocal(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocal(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      return localStorage.getItem(key);
    }
    
  }

}
