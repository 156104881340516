<form #formFirst="ngForm" id="profiloFornitoreForm">
    <div class="d-flex my-4">
        <div class="mr-auto">
            <h3 class="text-uppercase color-cprimary">{{testoContatto}}</h3>
        </div>
        <div class="pl-3">
            <button class="btn btn-bg-empty px-3" (click)="annullaModifiche()">CHIUDI</button>
        </div>
        <div class="pl-3">
            <button class="btn btn-bg-full px-3" [disabled]="formFirst.invalid" (click)="salvaContatto()">SALVA</button>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <label>Nome: <span class="txt-red">*</span></label>
            <input type="text" maxlength="200" class="form-control" id="nome" name="nome" [(ngModel)]="contattoFornitore.nome" required/>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Cognome: <span class="txt-red">*</span></label>
            <input type="text" maxlength="200" class="form-control" id="cognome" name="cognome" [(ngModel)]="contattoFornitore.cognome" required/>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Ruolo: <span class="txt-red">*</span></label>
            <select class="form-control" [(ngModel)]="contattoFornitore.ruolo" id="ruolo" name="ruolo" required>
              <option [ngValue]="undefined">Seleziona un ruolo</option>
              <option *ngFor="let ruolo of ruoli" [ngValue]="ruolo.codice">{{ruolo.descrizione}}</option>
            </select>
          </div>
        </div>
    </div>

    <div class="row ">
        <div class="col-md-4">
          <div class="form-group">
            <label>E-mail: <span class="txt-red">*</span></label>
            <input type="text" maxlength="200" class="form-control" id="email" name="email" [(ngModel)]="contattoFornitore.email" required/>
          </div>  
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Telefono: <span class="txt-red">*</span></label>
            <input type="text" maxlength="200" class="form-control" id="telefono" name="telefono" [(ngModel)]="contattoFornitore.telefono" required/>
          </div>
        </div>
      </div>
</form>
