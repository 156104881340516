import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { sharedConstants } from '../../const';
import { conferma } from '../../modals/confirm-modal/confirm-modal.component';
import { ToastService } from '../../_service/toast.service';

/**
 * Inputs:
 *
 * [title] -> Titolo dell'input
 * 
 * [required] -> Decide se mostrare o meno l'asterisco rosso di fianco al titolo
 *
 * Model:
 *
 * [(file)] ->    Di tipo FileFormat, é il file giá trasfgormato base64
 */
@Component({
  selector: 'app-og-upload-file',
  templateUrl: './og-upload-file.component.html'
})
export class OgUploadFileComponent implements OnInit{

  constructor(private modalService: NgbModal, private toastService: ToastService) { }

  @Input() title?: string;
  @Input() required = false;
  @Input() puntatore;
  @Input() file?: any;
  @Input() eliminazione: boolean;
  @Input() disabled = false;
  @Input() visualizzaRequired = false;
  @Input() download = false;
  @Output() eventDownload = new EventEmitter<void>();
  @Output() eventFileChange = new EventEmitter<FileList>();
  @Output() eventFileDelete = new EventEmitter<any>();
  @Input() titleBold = false;
  tipiFileToUpload = sharedConstants.tipiFileToUpload;
  idFile;

  ngOnInit() {
    // do nothing
  }

  fileChange(files: FileList) {
    if(files && files.length){
      files.item(0).size > 0 ? this.eventFileChange.emit(files) : this.toastService.showDanger('Il file caricato è vuoto. Riprova.');
    }
  }
  fileDelete(){
    conferma(this.modalService, 'Stai per eliminare il file ' + this.file.nomeFile +', confermi?', 'Conferma').then(ok => {
      if (ok) {
        if(this.file.id){
          this.eventFileDelete.emit(this.file.id);
        }
        this.file = null;
      }
    });
  }

  downloadFile(){
    this.eventDownload.emit();
  }
  }

