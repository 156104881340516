import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Utente } from 'src/app/shared/_model/common';
import { Fornitore } from 'src/app/shared/_model/fornitore';
import { Router } from '@angular/router';
import { OpenTelemetryService } from './open-telemetry/open-telemetry.service';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private openTelemetryService: OpenTelemetryService) { }

  login(user: any) {
    localStorage.token = 'Basic ' + btoa(`${user.username}:${user.password}`);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.post(environment.apiUrl + 'public/login', user, httpOptions)
      .pipe(map((res: any) => {

        if (res) {
          localStorage.user = JSON.stringify(res);
          localStorage.token = res.token;
          // DEVE SPARIRE SUBITO
          localStorage.pwd = user.password;
          localStorage.profilo = res.authorities[0].authority;
          this.openTelemetryService.idUtenteTrace = (JSON.parse(localStorage.getItem('user') || '{}').idUtente + '') || '';

          return res;
        }
      }));
  }

  resetPassword(pass: any, token: any) {
    return this.http.post(`${environment.apiUrl}public/resetPassword?token=${token}`, pass);
  }

  recuperaPassword(username: string) {
    return this.http.post(environment.apiUrl + 'public/tokenResetPassword' + '?username=' + username, {}).pipe(map((res: any) => res));
  }

  getUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }
  get userInfo(): Utente {
    return JSON.parse(localStorage.getItem('userinfo'));
  }

  get isLoggedIn() {
    return this.getUser() && localStorage.token;
  }

  get isAdminOrBackoffice() {
    return this.getUser().authorities[0].authority === 'ADM' || this.getUser().authorities[0].authority === 'FOR';
  }

  get isAdmin() {
    return this.getUser().authorities[0].authority === 'ADM';
  }

  get isOspedale() {
    return this.getUser().authorities[0].authority === 'OSP';
  }

  get isFornitore() {
    return this.getUser().authorities[0].authority === 'FRN';
  }

  get userType() {
    return this.getUser().authorities[0].authority;
  }


  checkerMissingInfoFornitore(resF: Fornitore) {
    let completeData = true;
    const keysToCheckIsFull = ['ragioneSociale', 'codiceFiscale', 'partitaIva', 'nome', 'cognome']; // Qua si possono aggiungere i campi da controllare come obbligatori;
    // tslint:disable-next-line: forin
    for (const k in resF) {
      const filtro = keysToCheckIsFull.find(key => key === k);
      if (filtro) {
        if (resF[k] === null) {
          completeData = false;
          break;
        }
      }
    }
    if (resF.emailComunicazioni) {
      const checkContatti =resF.emailComunicazioni.filter(key => key.nome && key.cognome && key.ruolo && key.telefono);
      if (checkContatti.length<1){
        completeData=false;
      }
    }
    
    if (completeData) {
      localStorage.ky_ = 4754398562150008;
      this.router.navigate(['/fornitori/home']).then(() => window.location.reload());
    } else {
      localStorage.ky_ = 4683247543856823;
      this.router.navigate(['/fornitori/configurazioni']);
    }
  }
}
