<ul class="nav flex-column pt-4" id="menuOspedale">
  <li class="nav-item mb-4">
    <a class="nav-link" [routerLink]="['/ospedale/home']" routerLinkActive="active-link">
      <i class="fas fa-home w-20px"></i>
      <span class="d-none d-xl-inline px-2">Dashboard</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/ospedale/lista-rdo']" routerLinkActive="active-link">
      <i class="fas fa-th-list w-20px"></i>
      <span class="d-none d-xl-inline px-2">Lista RDO</span>
    </a>
  </li>
  <li class="nav-item mb-4">
    <a class="nav-link" [routerLink]="['/ospedale/fornitori']" routerLinkActive="active-link">
      <i class="fas fa-user-tie w-20px"></i>
      <span class="d-none d-xl-inline px-2">Lista fornitori</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/ospedale/vendor-rating']" routerLinkActive="active-link">
      <i class="fas fa-comment-dots w-20px"></i>
      <span class="d-none d-xl-inline px-2">Vendor rating</span>
    </a>
  </li>
</ul>