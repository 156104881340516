import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { Injectable } from "@angular/core";

@Injectable()
export class CambiaEmailService {
  constructor(private http: HttpClient, private toastService: ToastService) {}

  cambioEmail(newMail: string){
    const body = JSON.parse(localStorage.getItem('userinfo'));
    body.email = newMail;
    this.http.put(environment.apiUrl + 'utente/update?lingua=' + environment.lang, body).subscribe((res)=>{
        localStorage.setItem('userinfo', JSON.stringify(res));
        this.toastService.showSuccess('Email è stata modificata con successo');
    })
  }
}
