import { RdoFornitore } from "./fornitore";

export class Utente {
    id: number;
    nome: string;
    cognome: string;
    username: string;
    email: string;
    lingua: string;
    profilo: string;
    area: string;
    fornitoreId: number;
    fornitore: string;
}

/********************************
********** FILE UPLOAD **********
********************************/
export class FileUpload {
    dataScadenza?;
    fornitoreId?: number;
    tipologiaDoc?: string;
    descrizione?: string;
    id?: number;
    rdoId?: number;
    idFileS3?: string;
    mimeType?: string;
    nomeFile?: string;
    stringBase64?: string;
    rdoFornitoriId?:number;
    elementoId?: number;
    descrizioneTipologiaDoc?: string;
    dataModifica?: Date;
}

export class FileFormat {
    constructor(file: File) {
        const filePunto = file.name.split('.');
        this.mimeType = filePunto[filePunto.length - 1];
        this.nomeFile = file.name;
        this.stringBase64 = '';
    }
    descrizioneTipologiaDoc?: string;
    tipologiaDoc?: string;
    rdoId?: number;
    fornitoreId?: number;
    rdoFornitoriId?: number;
    mimeType: string;
    nomeFile: string;
    id ?: number;
    stringBase64: string;
    ordineId?: number;
}

export class DichiarazioneUnica {
    id: number;
    data: string;
    dichiarazioneFinale: boolean;
    legaleProcuratore: string;
    idRdoFornitori: number;
    incompatibilita: string;
    jAntivirus: string;
    lDichiarazioneAggiuntiva: string;
    luogo: string;
    motivazioni: string;
    precauzioni: string;
    segretoTecnico: boolean;
    partiCoperteDaSegreto: string;
    requisitiImpiantistici: string;
    disponibilitaVisione: boolean;
    motivoDiniegoDisponibilitaVisione: string;
}

export class DichiarazioneLavori {
    id: number;
    offertaEconomicaId: number;
    lavDescrizione1: string;
    lavImporto1: number;
    lavDescrizione2: string;
    lavImporto2: number;
    lavDescrizione3: string;
    lavImporto3: number;
    importoOpereEdiliAcc: number;
    importoImpTermoidrauliciAcc: number;
    importoImpElettriciAcc: number;
    importoOpereEdiliSic: number;
    importoImpTermoidrauliciSic: number;
    importoImpElettriciSic: number;
    importoOneriSicurezza: number;
    _importoPrestazSecondaria: number;
    _importoLavoriMisura: number;

    set importoPrestazSecondaria(val: number) { this._importoPrestazSecondaria = val; }
    get importoPrestazSecondaria(): number {
        return this.lavImporto1 + this.lavImporto2 + this.lavImporto3 + this.importoOpereEdiliAcc + this.importoImpTermoidrauliciAcc +
               this.importoImpElettriciAcc + this.importoOpereEdiliSic + this.importoImpTermoidrauliciSic + this.importoImpElettriciSic;
    }
    set importoLavoriMisura(val: number) { this._importoLavoriMisura = val; }
    get importoLavoriMisura(): number {
        return this.importoOpereEdiliAcc + this.importoImpTermoidrauliciAcc + this.importoImpElettriciAcc + 
               this.importoOpereEdiliSic + this.importoImpTermoidrauliciSic + this.importoImpElettriciSic;
    }
    
    constructor(offertaEconomicaId?: number){
        this.id = 0;
        this.offertaEconomicaId = offertaEconomicaId || 0;
        this.lavImporto1 = 0;
        this.lavImporto2 = 0;
        this.lavImporto3 = 0;
        this.importoOpereEdiliAcc = 0;
        this.importoImpTermoidrauliciAcc = 0;
        this.importoImpElettriciAcc = 0;
        this.importoOpereEdiliSic = 0;
        this.importoImpTermoidrauliciSic = 0;
        this.importoImpElettriciSic = 0;
        this.importoLavoriMisura = 0;
        this.importoPrestazSecondaria = 0;
        this.importoOneriSicurezza = 0;
        this.lavDescrizione1 = "Progettazione definitiva";
        this.lavDescrizione2 = "Progettazione esecutiva";
        this.lavDescrizione3 = "Coordinamento della sicurezza in fase di progettazione";
    }
}

export class DichiarazioneRitiro {
    id: number;
    offertaEconomicaId: number;
    importoSmaltimentoRitiro: number;
    proceduraRitiro: string;
    smaltimentoAttrezz: boolean;
    constructor(offertaEconomicaId?: number) {
        this.id = 0;
        this.offertaEconomicaId = offertaEconomicaId || 0;
        this.importoSmaltimentoRitiro = 0;
        this.smaltimentoAttrezz = null;
    }
}

class VoceCapitolato {
    id: number;
    gruppoId: number;
    titoloVoce: string;
    ordine: number;
    rispostaFornitore: string; // penso sia string
}

export class Capitolato {
    id: number;
    rdoId: number;
    titoloGruppo: string;
    voci: VoceCapitolato[];
    ordine: number;
}

export class DescrizioneParticolareggiata {
    id: number;
    idCapitolato: number;
    idRdoFornitori: number;
    risposta: string;
}

export class OffertaEconomica {
    id: number;
    rdoFornitoriId: number;
    dichiarazioneLavoriId: number;
    dichiarazioneOffertaId: number;
    dichiarazioneRitiroId: number;
    verificaUsoConsumabili: boolean;
    verificaUsoElementi: boolean;
    verificaUsoOpzioni: boolean;
    deleted: boolean;
} 

export class Quesito{
    bozza: boolean;
    id: number;
    idFornitore: number;
    idRdo: number;
    idRdoFornitori: number;
    archivia: boolean;
    cancella: boolean;
    dataInvioOspedale: string;// "2021-03-10T15:21:35.009Z";
    dataModificaFornitore: string;
    dataModificaOG: string;
    dataPubblicazione: string;
    dataCreazione: string;
    domandaOriginale: string;
    domandaOspedale: string;
    domandaPubblica: string;
    emailOspedale: string;
    note: string;
    pubblica: boolean;
    risposta: string;
    rispostaPrivata: string;
    titoloOriginale: string;
    titoloOspedale: string;
    titoloPubblica: string;
    numProcedura: string;
    ragioneSociale: string;
    nomeProcedura: string;

    constructor(rdoFornitore?: RdoFornitore){
       this.id = 0;
       this.idFornitore = rdoFornitore.fornitoreId || 0;
       this.idRdo = rdoFornitore.rdoId || 0;
       this.idRdoFornitori = rdoFornitore.id || 0;
    }
}

export class FileData {
    id?: number;
    idFileS3?: string;
    nomeFile?: string;
    descrizione?: string;
    fornitoreId?: number;
    rdoId?: number;
    rdoFornitoriId?: number;
    elementoId?: number;
    tipologiaDoc?: string;
    dataScadenza?: string;
    stringBase64?: string;
    mimeType?: string;
    consegnaCollaudoId?: number;
    ordineId?: number;
  }

export class Visione{
    constructor(idRdoFornitore?: number, idRdo?:number){
        this.idRdoFornitore = idRdoFornitore;
        this.idRdo = idRdo;
    }
    idCertificatoCe: number;
    idDichiarazioneConformita: number;
    cancella: boolean;
    codiceDichiarazioneConformita: string
    codiceItem: string
    dataRilascioCertificatoCe: Date | string;
    dataScadenzaCertificatoCe: Date | string;
    dataValiditaDichiarazioneConformita: Date | string;
    elemento: Elemento;
    id: number;
    idElemento: number;
    idOpzione: number;
    idRdo: number;
    idRdoFornitore: number;
    marca: string;
    modello: string;
    motivoDiniego: string;
    opzione: Opzione;
    visionePerOpzione: boolean;
    active = false;
    nomeCertificatoCe:string;
    nomeDichiarazioneConformita: string;
    sceltoPerVisione: boolean
}

export class Elemento{
    annoOrdineDef: string;
    certCeDataFine: string;
    certCeDataInizio: string;
    certificatoCE: boolean;
    classe: string;
    cnd: string;
    codice: string;
    copiaChiaviSoftware: boolean;
    dataDichiarazConf: string;
    descrizione: string;
    dichiarazioneConformita: boolean;
    dichiarazioneConformitaRohs: boolean;
    dichiarazioneFabbricante: string;
    docCertificatoCEOrdine:	FileData;
    docDepliant: FileUpload[];
    docDepliantBrochureSchedaTecnica: FileUpload[];
    docDichiarazioneConformitaOrdine: FileData;
    docManualeTecnicoManutenzFabb: FileData;
    docManualeUsoIta: FileData;
    docPartList: FileData;
    docProtocolliManutenzPrev: FileData;
    docSchedaTecnica: FileUpload[]
    docSchemiElettrici: FileData;
    fornitoreDistributore: string;
    id: number;
    idDocCertificatoCE: number;
    idDocDestinazioneUso: number;
    idDocDichiarazioneConformita: number;
    idDocDichiarazioneConformitaRohs: number;
    idDocManualeUsoItaliano: number;
    idDocProtocolliManutenzionePreventiva: number;
    idDocManualeTecnicoManutenzFabb: number;
    idDocPartList: number;
    idDocSchemiElettrici: number;
    idDocCopiaSoftware: number;
    listaCompletaDocumenti: FileData[];
    listaDocumentiAggiuntivi: FileUpload[];
    manualeItaliano: boolean;
    manualeTecnicoManutenzFabb: boolean;
    marca: string;
    meseOrdineDef: string;
    modello: string;
    noteOrdineDef: string;
    numSerieElemento: string;
    offertaEconomicaId: number;
    partList: boolean;
    prezzoListino: number;
    prezzoTotale: number;
    prezzoUnitario: number;
    quantita: string;
    rdm: string;
    schemiElettrici: boolean;
    scontoPercentuale: number;
    tipo: string;
    tipologiaManuale: string;
  filePerOrdine: string;
    tipologiaProtocolli: string;
    sceltoPerVisione: boolean;
  sceltoPerOrdine: any;
}

export class Opzione{
    cnd: string;
    codice: string;
    descrizione: string;
    fornitoreDistributore: string;
    id: number;
    marca: string;
    modello: string;
    offertaEconomicaId: number;
    prezzoTotale: number;
    prezzoUnitario: number;
    quantita: string;
    rdm: string;
    sceltoPerVisione: boolean;
  sceltoPerOrdine: boolean;
}