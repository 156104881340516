import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RdoFornitoreService {

  constructor(private http: HttpClient) { }
  getListFornitoriByRdo(idRdo: number, isVisione: boolean, conElementiOpzioni: boolean): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/list/' + idRdo + '/' + isVisione + '/' + conElementiOpzioni + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  downloadFile(idFile: number) {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/file/download/' + idFile + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }
}
