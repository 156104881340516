<nav class="navbar fixed-top bg-color-white flex-md-nowrap" >
  <a class="navbar-brand ml-2 d-lg-block cursorPointer" (click)="apriModaleLogin()">
    <img src="assets/img/logoOGorizzontale.png">
  </a>
  <ul class="navbar-nav px-3 d-flex flex-row align-items-center">
  </ul>
</nav>

<div class="container-fluid" id="main-container">
  <div class="row containerFluidWrapper">
    <div class="col-xl-12 col-12 ml-sm-auto px-5">
      <div class="col-12 mx-auto listaStepOrizzontale">
        <ul class="d-flex navbar-nav flex-row justify-content-between tab-section">
          <li (click)="changeStep('stepDettagli')" [class.active-tab]="stepDettagli == true" [class.disabled]="stepFine">Dettagli</li>
          <li (click)="changeStep('stepTag')" [class.active-tab]="stepTag == true" [class.disabled]="isStepCompleated('stepTag') || stepFine">TAG</li>
          <li (click)="changeStep('stepContatti')" [class.active-tab]="stepContatti == true" [class.disabled]="isStepCompleated('stepContatti') || stepFine">Contatti</li>
          <li (click)="changeStep('stepLegaleRappresentante')" [class.active-tab]="stepLegaleRappresentante == true" [class.disabled]="isStepCompleated('stepLegaleRappresentante') || stepFine">Firmatario<br/>offerta</li>
          <li [class.active-tab]="stepFine == true" [class.disabled]="!stepFine">Fine</li>
        </ul>
      </div>
      
      <app-dettagli *ngIf="stepDettagli == true" (nextStep)="stepCompleted('stepTag')"></app-dettagli>
      <app-tag *ngIf="stepTag == true"
        (nextStep)="stepCompleted('stepContatti')"
        (previousStep)="stepBefore('stepDettagli')"></app-tag>
      <app-contatti *ngIf="stepContatti == true"
        (nextStep)="stepCompleted('stepLegaleRappresentante')"
        (previousStep)="stepBefore('stepTag')"></app-contatti>
      <app-legale-rappresentante *ngIf="stepLegaleRappresentante == true"
        (lastStep)="salvaNuovoFornitore()"
        (previousStep)="stepBefore('stepContatti')"></app-legale-rappresentante>
      <div *ngIf="stepFine == true" class="dashboard">
        <div class="d-flex">
          <div class="mr-auto">
            <h3 class="text-uppercase color-cprimary">Fine</h3>
          </div>
        </div>
        
        <p>Registrazione avvenuta con successo!</p>
        <p>A breve riceverà una mail all'indirizzo: {{nuovoFornitore.pec}}.</p>
        <p>La mail in questione contiene le istruzioni per eseguire il primo accesso.</p>
        <p><a href="" (click)="linkFineCompilazione()">Torna alla login</a></p>
      </div>
    </div>
  </div>
</div>
