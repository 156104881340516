import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../_service/auth.service';

@Injectable()
export class OspedaleGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    public canActivate() {
      if (localStorage.token && localStorage.user) {
        if(this.authService.isOspedale) {
          return true;
        } else {
          this.router.navigate(['/404']);
          return false;
        }
      } else {
        this.router.navigate(['/auth/login']);
        return false;
      }
    }
}
