import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styles: []
})
export class ContattiComponent implements OnInit {

  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();

  stepListaContatti = true;
  contattiFornitore = [];

  constructor() {
    // do nothing
  }

  ngOnInit() {
    this.getListaContattiFornitoreDalLocalStorage();
  }

  changeStep(item: any) {
    // !this.stepListaContatti && item: ho aggiunto un nuovo contatto dal componente nuovo-contatto
    if (!this.stepListaContatti && item) {
      // aggiorno la lista di contattiFornitore
      this.getListaContattiFornitoreDalLocalStorage();
      // aggiungo l'item
      this.contattiFornitore.push(item);
      // aggiorno il localStorage
      localStorage.setItem('contattiFornitore', JSON.stringify(this.contattiFornitore));
    }

    this.stepListaContatti = !this.stepListaContatti;
  }

  continua() {
    localStorage.setItem('contattiFornitore', JSON.stringify(this.contattiFornitore));

    this.nextStep.emit();
  }

  indietro() {
    localStorage.setItem('contattiFornitore', JSON.stringify(this.contattiFornitore));

    this.previousStep.emit();
  }

  getListaContattiFornitoreDalLocalStorage() {
    if (localStorage.getItem('contattiFornitore')) {
      this.contattiFornitore = JSON.parse(localStorage.getItem('contattiFornitore'));
    }
  }
}
