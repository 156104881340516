import { Directive, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Directive({
  selector: 'form'
})
export class FormSubmitDirective {
  expressionResult: boolean;
  submit$ = fromEvent(this.element, 'submit')
    .pipe(tap(() => {
      // do nothing
    }), shareReplay(1));

  constructor(private host: ElementRef<HTMLFormElement>) {
    // do nothing
  }

  get element() {
    return this.host.nativeElement;
  }
}
