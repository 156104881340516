import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Fornitore, ContattiFornitore, RdoFornitore } from 'src/app/shared/_model/fornitore';
import { Capitolato, DescrizioneParticolareggiata, DichiarazioneRitiro, FileUpload, OffertaEconomica } from 'src/app/shared/_model/common';
import { Ordine } from '../_model/ordine';
import { Injectable } from "@angular/core";

@Injectable()
export class FornitoriService {
  constructor(private http: HttpClient) { }




  /*******************************
  *********** FORNITORI **********
  *******************************/
  listaFornitori(optional?: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'fornitore/list?lingua=' + environment.lang + optional).pipe(map((res: any) => res));
  }

  nuovoFornitore(jsonIn: Fornitore): Observable<any> {
    return this.http.post(environment.apiUrl + 'fornitore/create?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  updateFornitore(jsonIn: Fornitore): Observable<any> {
    return this.http.put(environment.apiUrl + 'fornitore/update', jsonIn).pipe(map((response: any) => response));
  }

  dettaglioFornitore(id: number | string) {
    return this.http.get(environment.apiUrl + 'fornitore/read/' + id + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  deleteFornitore(idFornitore: number) {
    return this.http.delete(environment.apiUrl + 'fornitore/delete/' + idFornitore + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }


  /******************************
  *********** CONTATTI **********
  ******************************/
  insertContatto(jsonIn: ContattiFornitore): Observable<any> {
    return this.http.post(environment.apiUrl + 'fornitore/contatto/create?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  updateContatto(jsonIn: ContattiFornitore): Observable<any> {
    return this.http.put(environment.apiUrl + 'fornitore/contatto/update?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }
  // Elimina un contatto esistente di un fornitore
  deleteContatto(idContato): Observable<any> {
    return this.http.delete(environment.apiUrl + 'fornitore/contatto/delete/' + idContato + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((res: any) => res));
  }

  /*******************************
  *********** DOCUMENTI **********
  *******************************/
  listaDocumenti(idFornitore): Observable<any> {
    return this.http.get(environment.apiUrl + 'fornitore/file/list/' + idFornitore + '?lingua=' + environment.lang).pipe(map((res: any) => res));
  }

  insertUpdateFile(jsonIn: FileUpload[]): Observable<any> {
    return this.http.post(environment.apiUrl + 'fornitore/files/update?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  getFile(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'fornitore/file/get-info-doc/' + idDocumento + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }
  
  downloadFile(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'fornitore/file/download/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  deleteFile(idDocumento: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'fornitore/file/delete/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  /*********************************************
  *********** DOCUMENTI RDO FORNITORE **********
  *********************************************/

  insertUpdateFileMultiplo(jsonIn: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'rdo-fornitore/files/update?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  // Lista di documenti amministrativi relativi all'associazione rdo-fornitore;
  getListaDocRdoFornitore(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/file/list/' + id + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Recupero di un documento amministrativo relativo all'associazione rdo-fornitore
  downloadFileRdoFornitore(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/file/download/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  deleteFileRdoFornitore(idDocumento: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'rdo-fornitore/file/delete/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  // Ottieni documento amministrativo relativo a doc rdo-fornitor
  getFileInfoRdoFornitore(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/file/get-info-doc/' + idDocumento + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  //scarica file zip di tutti i doc di un rdo fornitore
  downloadDocZip(idRdoFornitore: number, flagZipOffertaCompleta: boolean): Observable<any> {
    return this.http.get(environment.apiUrl + 'template/file/download/zip/doc/' + idRdoFornitore + '?lingua=' + environment.lang + '&completa=' + flagZipOffertaCompleta, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  //ritorna i templates di un codice
  getListaTemplateByCodice(filtri: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'template/lista', filtri).pipe(map((response: any) => response));
  }


  //imposta un template email come preferito
  setTemplatePreferito(idTemplate: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'template/salvaTemplatePreferito?idTemplate='+idTemplate).pipe(map((response: any) => response));
  }

  //crea o aggiorna un template
  setTemplate(template: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'template/salva', template).pipe(map((response: any) => response));
  }
   
  downloadPdfZip(idRdo: number, codiceTemplate: string): Observable<any> { 
    return this.http.get(environment.apiUrl + 'template/file/download/zip/pdf/' + codiceTemplate + '/' + idRdo + '?lingua=' + environment.lang, { responseType:'blob' }).pipe(map((response: any) =>response));
  }
   
  downloadXlsZip(idRdo: number, codiceTemplate: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'template/file/download/zip/xls/' + codiceTemplate + '/' + idRdo + '?lingua=' + environment.lang, { responseType:'blob' }).pipe(map((response: any) =>response));
  }
  
  getFileOffertaDuplicata(idRdoDuplicata: number) {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/fileOffertaDuplicata/' + idRdoDuplicata).pipe(map((response: any) => response));
  }

  /***************************
  *********** ALTRI **********
  ****************************/

  // Lista di documenti amministrativi relativi all'associazione rdo-fornitore;
  getListaDocRdoFornitoreByTipoDoc(idRdoFornitore: number, tipoDoc: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/file/list-by-tipologia/' + idRdoFornitore + '/' + tipoDoc + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }


  /***********************************
 *********** RDO FORNITORE  **********
 ************************************/

  // Ottieni lista RDO di un fornitore;
  getListRDOById(idFornitore: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/list/' + idFornitore + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Ottieni lista RDO di un fornitore da visualizzare internamente al fornitore;
  getListRDOByIdInternal(idFornitore: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/listNew/' + idFornitore + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Fa la Scelta di partecipare o no ad UNA RDO;
  partecipaRDO(scelta: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'rdo-fornitore/participation' + '?lingua=' + environment.lang, scelta).pipe(map((response: any) => response));
  }







  getListFornitoriByRdo(idRdo: number, isVisione: boolean, conElementiOpzioni: boolean): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/list/' + idRdo + '/' + isVisione + '/' + conElementiOpzioni + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }
  /**
   * Ottieni un Rdo Fornitore dall'id fornitore e id RDO
   * @param idRdo id della RDO
   * @param idFornitore id del fornitore
   */
  getRdoFornitore(idFornitore: number | string, idRdo: number | string): Observable<RdoFornitore> {
    return this.http.get<RdoFornitore>(environment.apiUrl + `rdo-fornitore/read/${idRdo}/${idFornitore}?lingua=${environment.lang}`);
  }


  /**
   * Aggiorna rdo fornitore
   * @param query 
   * @returns 
   */
  updateRdoFornitore(query: RdoFornitore): Observable<any> {
    return this.http.post(environment.apiUrl + 'rdo-fornitore/save', query);
  }


  submitConfermaOfferta(idRdoFornitore: number) : Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/submitConfermaOfferta/' + idRdoFornitore).pipe(map((response: any) => response));
  }

  changeRdoFornitoreAccettatoConfermaOfferta(idRdoFornitore: number, conferma: boolean) : Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo-fornitore/confermaOfferta/' + idRdoFornitore + '/' + conferma).pipe(map((response: any) => response));
  }

  /***********************************
   *********** DA SPOSTARE  **********
   ************************************/
  // GET Lista dei ruoli dei contatti del fornitore;
  getListaRuoloContatti(): Observable<any> {
    return this.http.get(environment.apiUrl + 'domini/ruolo/contattiFornitore/list').pipe(map((response: any) => response));
  }

  // Lista dei ruoli del Legale del fornitore;
  getListaRuoloLegaleRappresentante(): Observable<any> {
    return this.http.get(environment.apiUrl + 'domini/ruolo/legale/list').pipe(map((response: any) => response));
  }

  // Lista dei tipi di materiali di consumo
  listaMaterialiConsumo(): Observable<any> {
    return this.http.get(environment.apiUrl + 'domini/tipiMaterialiConsumo').pipe(map((response: any) => response));
  }

  // Dettaglio di UNA RDO by {idRDO};
  getDettaglioRDOById(idRDO: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'rdo/read/' + idRDO).pipe(map((response: any) => response));
  }

  /***************************************
  *********** Offerta Tecnica   **********
  ***************************************/

  /**
   *  Ottieni il documento capitolato tramite id RDO;
   */
  capitolatoRdoFornitore(idRdo: number): Observable<Capitolato[]> {
    return this.http.get<Capitolato[]>(`${environment.apiUrl}capitolato/${idRdo}?lingua=${environment.lang}`);
  }

  /**
   *  Ottieni il documento capitolato tramite id RDO;
   */
  descrizioniParticolareggiateRdoFornitore(idRdoFornitore: number): Observable<DescrizioneParticolareggiata[]> {
    return this.http.get<DescrizioneParticolareggiata[]>(`${environment.apiUrl}descriz-part/read-by-rdo-fornitore/${idRdoFornitore}?lingua=${environment.lang}`);
  }

  // Ottieni il documento capitolato tramite id RDO;
  saveDescrizioneParticolareggiataRdoFornitore(jsonIn: { idCapitolato: string, idRdoFornitori: string, risposta: string }): Observable<any> {
    return this.http.post(environment.apiUrl + 'descriz-part/save', jsonIn).pipe(map((response: any) => response));
  }



  /******************************* Progetto Def Lavori Controller **************************************/

  // Caricamento di uno o più documenti di progetto definitivo lavori;
  uploadProgettoDefinitivo(progettoDefinitivo: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'progetto-definitivo-lavori/files/update' + '?lingua=' + environment.lang, progettoDefinitivo).pipe(map((response: any) => response));
  }

  // Lista di documenti di progetto definitivo lavori tramite id rdo fornitore;
  documentiProgettoDefinitivoList(idRdoFornitore: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'progetto-definitivo-lavori/file/list/' + idRdoFornitore + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Recupero di un documento di progetto definitivo lavori tramite id del documento;
  downloadFileOfProgettoDefinitivo(idDocumento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'progetto-definitivo-lavori/file/download/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'blob' }).pipe(map((response: any) => response));
  }

  // Eliminazione di un documento di progetto definitivo lavori;
  deleteFileofProgettoDefinitivo(idDocumento: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'progetto-definitivo-lavori/file/delete/' + idDocumento + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }


  /***************************************
  *********** Offerta Economica **********
  ***************************************/

  /******************************* Offerta Economica CRUD *********************************/
  /**
   * Ottieni un'offerta economica da id offerta economica
   */
  getOffertaEconomica(idOffertaEconomica: any): Observable<OffertaEconomica> {
    return this.http.get<OffertaEconomica>(`${environment.apiUrl}offerta-economica/read/${idOffertaEconomica}?lingua=${environment.lang}`);
  }
  /**
   * Modifica offerta economica esistente
   *
   */
  updateOffertaEconomica(offertaEconomica: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}offerta-economica/update?lingua=${environment.lang}`, offertaEconomica);
  }

  // Ottieni un'offerta economica tramite id rdo fornitore
  offertaEconomica(idRdoFornitore: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/read-by-rdo-fornitore/' + idRdoFornitore + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  /******************************* Offerta Economica ELEMENTO **************************************/
  // Crea un elemento
  creaNuovoElemento(elemento: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'offerta-economica/elementi/create' + '?lingua=' + environment.lang, elemento).pipe(map((response: any) => response));
  }

  // Clona un elemento
  clonaElemento(idOffertaEconomica: number, idElemento: number): Observable<any> {
    return this.http.post(environment.apiUrl + 'offerta-economica/'+ idOffertaEconomica + '/elementi/'+ idElemento +  '/duplica?lingua=' + environment.lang, null).pipe(map((response: any) => response));
  }
  

  // Modifica un elemento esistente
  updateElemento(jsonIn: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'offerta-economica/elementi/update' + '?lingua=' + environment.lang, jsonIn).pipe(map((response: any) => response));
  }

  // Ottieni una lista di elementi
  elementiList(idOffertaEconomica: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/elementi/list/' + idOffertaEconomica + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Elimina un elemento
  deleteElemento(idElemento: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'offerta-economica/elementi/delete/' + idElemento + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  // Dettaglio elemento
  dettaglioElemento(idElemento: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/elementi/read/' + idElemento + '?lingua=' + environment.lang);
  }

  /******************************* Offerta Economica OPZIONI **************************************/

  // Crea un'opzione
  creaNuovaOpzione(opzione: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'offerta-economica/opzioni/create' + '?lingua=' + environment.lang, opzione).pipe(map((response: any) => response));
  }

  // Modifica un'opzione esistente
  updateOpzione(opzione: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'offerta-economica/opzioni/update' + '?lingua=' + environment.lang, opzione).pipe(map((response: any) => response));
  }

  // Ottieni una lista di opzioni
  opzioniList(idOffertaEconomica: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/opzioni/list/' + idOffertaEconomica + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Ottieni una  opzione
  getOpzione(idOpzione: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/opzioni/read/' + idOpzione + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Elimina un'opzione
  deleteOpzione(idOpzione: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'offerta-economica/opzioni/delete/' + idOpzione + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  /******************************* Offerta Economica Dichiarazione Ritiro **************************************/

  /**
   * Crea una dichiarazione ritiro
   * @param query
   */
  creaDicRitiro(query: DichiarazioneRitiro): Observable<DichiarazioneRitiro> {
    return this.http.post<DichiarazioneRitiro>(`${environment.apiUrl}offerta-economica/dichiaraz-ritiro/create?lingua=${environment.lang}`, query);
  }

  /**
   * Modifica una dichiarazione ritiro esistente
   * @param query
   */
  updateDicRitiro(query: DichiarazioneRitiro): Observable<DichiarazioneRitiro> {
    return this.http.put<DichiarazioneRitiro>(`${environment.apiUrl}offerta-economica/dichiaraz-ritiro/update?lingua=${environment.lang}`, query);
  }

  // Ottieni una dichiarazione ritiro
  getDicRitiro(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/dichiaraz-ritiro/read/' + id + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  /**
   *  Ottieni una dichiarazione ritiro by idOffertaEconomica
   * @param idOffertaEconomica id dell'offerta economica
   */
  getDicRitiroByIdOfferta(idOffertaEconomica: any): Observable<DichiarazioneRitiro> {
    return this.http.get<DichiarazioneRitiro>(`${environment.apiUrl}offerta-economica/dichiaraz-ritiro/read-by-rdo-fornitore/${idOffertaEconomica}?lingua=${environment.lang}`);
  }

  /******************************* Offerta Economica Dichiarazione Offerta **************************************/

  // Crea una dichiarazione offerta
  creaDicOfferta(dicOfferta: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'offerta-economica/dichiaraz-offerta/create' + '?lingua=' + environment.lang, dicOfferta).pipe(map((response: any) => response));
  }

  // Modifica una dichiarazione offerta esistente
  updateDicOfferta(dicOfferta: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'offerta-economica/dichiaraz-offerta/update' + '?lingua=' + environment.lang, dicOfferta).pipe(map((response: any) => response));
  }

  // Ottieni una dichiarazione offerta
  getDicOfferta(idOffertaEconomica: any): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/dichiaraz-offerta/read-by-off-economica/' + idOffertaEconomica + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  /******************************* Offerta Economica Consumabili **************************************/

  // Crea un consumabile
  creaConsumabile(consumabile: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'offerta-economica/consumabili/create' + '?lingua=' + environment.lang, consumabile).pipe(map((response: any) => response));
  }

  // Modifica un consumabile esistente
  updateConsumabile(consumabile: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'offerta-economica/consumabili/update' + '?lingua=' + environment.lang, consumabile).pipe(map((response: any) => response));
  }

  // Ottieni un consumabile
  getConsumabile(idConsumabile: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/consumabili/read/' + idConsumabile + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Ottieni una lista di consumabili di un'offerta economica
  getListaConsumabile(idOffertaEconomica: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-economica/consumabili/list/' + idOffertaEconomica + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Elimina una consumabile
  deleteConsumabile(idConsumabile: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'offerta-economica/consumabili/delete/' + idConsumabile + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  /********************************************
  *********** Offerta Amministrativa **********
  ********************************************/
  // Crea un'offerta amministrativa;
  creaOffertaAmministrativa(offertaAmministrativa: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'offerta-amministrativa/create' + '?lingua=' + environment.lang, offertaAmministrativa).pipe(map((response: any) => response));
  }

  // Ottieni un'offerta amministrativa tramite id rdo fornitore;
  getOffertaAmministrativa(idRdoFornitore: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'offerta-amministrativa/read-by-rdo-fornitore/' + idRdoFornitore + '?lingua=' + environment.lang).pipe(map((response: any) => response));
  }

  // Modifica un'offerta amministrativa esistente;
  updateOffertaAmministrativa(offertaAmministrativa: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'offerta-amministrativa/update' + '?lingua=' + environment.lang, offertaAmministrativa).pipe(map((response: any) => response));
  }

  // Elimina un'offerta amministrativa;
  deleteOffertaAmministrativa(idOffertaAmministrativa: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'offerta-amministrativa/delete/' + idOffertaAmministrativa + '?lingua=' + environment.lang, { responseType: 'text' }).pipe(map((response: any) => response));
  }

  // Template selezionato tramite codice e id RDO (ART 80)
  getTemplateArt80OffertaAmministrativa(): Observable<any> {
    return this.http.get(environment.apiUrl + 'template/html-base/A80', { responseType: 'text' }).pipe(map((response: any) => response));
  }

  /**
   * Template selezionato tramite codice e id RDO
   * @param codiceTemplate
   * @param idRdoFornitore
   */
  getTemplatePdf(codiceTemplate: string, idRdoFornitore: number, idRdo: number): Observable<any> {
    return this.http.get(environment.apiUrl + `template/fornitore/pdf/${codiceTemplate}/${idRdoFornitore}?lingua=${environment.lang}&idRdo=${idRdo}`, { responseType: 'blob'});
  }


  /****************************************************
   ******************** ORDINE ************************
   ****************************************************/

  getOrdine(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'ordine/read/' + id).pipe(map((response: any) => response));
  }

  getOrdiniByRdoFornitore(idRdoFornitore: number): Observable<any> {
    return this.http.post(environment.apiUrl + `ordine/list?lingua=${environment.lang}&idRdoFornitore=${idRdoFornitore}`, {}).pipe(map((response: any) => response));
  }

  getOrdiniByRdo(idRdo: number): Observable<any> {
    return this.http.post(environment.apiUrl + `ordine/list?lingua=${environment.lang}&idRdo=${idRdo}`, {}).pipe(map((response: any) => response));
  }

  saveOrdini(ordini: Ordine[]): Observable<any> {
    return this.http.post(environment.apiUrl + 'ordine/createUpdate/', ordini).pipe(map((response: any) => response));
  }

  /****************************************************
  ******************* DASHBOARD ***********************
  ****************************************************/

  getDashboardFornitore(idFornitore: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'dashboard/fornitore/' + idFornitore).pipe(map((response: any) => response));
  }

  getDashboardOG(idAreaRdo: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'dashboard/ospedalGrando/' + idAreaRdo).pipe(map((response: any) => response));
  }

  /**
   * salvataggio del fornitore tramite il form di autoregistrazione
   * 
   * @param nuovoFornitore
   */
  saveAccreditamentoFornitore(nuovoFornitore: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'public/fornitore/wizard/', nuovoFornitore).pipe(map((response: any) => response));
  } 
  
}
