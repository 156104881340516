import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/shared/_service/toast.service';
import { AuthService } from '../_service/auth.service';

@Component({
  selector: 'app-modifica-password',
  templateUrl: './modifica-password.component.html'
})
export class ModificaPasswordComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
    ) { }

  jsonin: any;
  token: any;

  ngOnInit() {
    this.jsonin = {};
    this.token = this.activatedRoute.snapshot.paramMap.get('tkn');
  }

  modificaPassword() {
    if(this.token){
      this.auth.resetPassword(this.jsonin, this.token).subscribe(
        () =>this.router.navigate(['/auth/login']),
        () => this.toastService.showDanger('Qualcosa non è andata a buon fine, il link di recupero potrebbe non essere più valido. Riprova!')
      );
    } 
  }

}
