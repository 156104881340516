import { FileData } from 'src/app/shared/_model/common';
import { Integration } from './integrazione';

export class Fornitore {
    area?: string;
    cap?: string;
    citta?: string;
    codiceFiscale?: string;
    cognome?: string;
    comuneNascita?: string;
    dataNascita?: string;
    emailComunicazioni?: ContattiFornitore[];
    id?: number;
    lingua?: string;
    nazione?: string;
    nome?: string;
    ruolo?: string;
    partitaIva?: string;
    pec?: string;
    ragioneSociale?: string;
    via?: string;
    telefono?: number;
    selezionato?: boolean;
    scelta?: any;
    cittaResidenza?: string;
    indirizzoResidenza?: string;
    identitaFirmatarioOfferta?: IdentitaFirmatarioOfferta;
    scadenzaDocumentoFirmatario?: string;
    selezionatoInfoRdo?: boolean;
    integrazioniAperte?: boolean;
    fornitoreScelto?: boolean;
    qtaDocumentiOfferta?: number;
    motivoDiniegoOG?: string;
    motivoDiniegoFornitore?: string
    selezionatoDB?:boolean;
    noteOg?: string;
    idFornitore?: number;
    hidden?: boolean;
}

export class RdoFornitore {
    dataCreazione: string;
    id: number;
    partecipazioneGara: boolean;
    dataPartecipazione: string;
    offertaPresentata: boolean;
    dataPresentazioneOfferta: string;
    allegatiUpload: boolean;
    motivoDiniego: string;
    numOfferta: number;
    ggConsegna: string;
    dataInvito: string;
    rdoId: number;
    fornitoreId: number;
    offertaEconomicaId: number;
    submitCapitolato: boolean;
    submitConfermaOfferta: boolean;
    submitConsumabili: boolean;
    submitDichiarazioneOfferta: boolean;
    submitDichiarazioneLavori: boolean;
    submitDichiarazioneRitiro: boolean;
    submitDichiarazioneUnica: boolean;
    submitElementi: boolean;
    submitNominativoLavori: boolean;
    submitOffertaAmministrativa: boolean;
    submitOpzioni: boolean;
    submitProgettoDefinitivo: boolean;
    submitPuntoPunto: boolean;
    submitScadenza:boolean;
    firmatarioOfferente: string;
    firmatarioLavori: string;
    noteOG: string;
    fornitoreScelto: boolean;
    integrazioniAttive: Integration[];
    integrazioniChiuse: Integration[];
    motivoDiniegoOG: string;
    sceltoConfermaOrdine: boolean;
    sceltoPerVisione: boolean;
    submitVisione: boolean;
    visioneAccettata: boolean;
    submitOrdineDefinitivo: boolean;
    submitOrdineProvvisorio: boolean;
    noteOgVisione: string;
    ragioneSociale?: any;
    visioneIndicazioni: string;
    docDvr: number;
    docModelloVisione: number;
    motivoDiniegoVisione: string;
    confermaOfferta: boolean;
    idRdoDuplicato: number;
    giorniValiditaOfferta: number;
    giorniConsegnaOfferta: number;
    scontoOfferta: number;
    protocolloConfermaOfferta: string;
    motivoDiniegoFornitore: string;
    qtaDocumentiOfferta: any;
    accettataConfermaOfferta: boolean;
    salvataggioOg: boolean;
}

export class ContattiFornitore {
    cognome: string;
    email: string;
    fornitoreId: number;
    id: number;
    ragioneSocialeFornitore: string;
    nome: string;
    telefono: number;
    ruolo:string;
    descrizioneRuolo: string;
}

class IdentitaFirmatarioOfferta extends FileData {}